
$width: 100%;
$height: 100%;

$breakpoint-tablet: 768px;

$pc-media:1200px;
$pcs-media:960px;
$pad-media:768px;
$pads-media:767px;
$phone-media:480px;

//電腦
@mixin pc-width() {
  @media all and (min-width: $pc-media) {
        @content;
     }
}

//平板
@mixin pad-width() {
@media all and (min-width:$pad-media) and (max-width: $pcs-media) {
            @content;
      }
}
//小平板
 @mixin small-pad-width() {
    @media all and (min-width: $phone-media) and (max-width:$pads-media) {
            @content;
      }
 }
//手機
 @mixin phone-width() {
    @media all and (max-width: $phones-media) {
            @content;
      }
 }

//media-queries的最大寬度
@mixin screen($res-max){ 
  @media screen and ( max-width: $res-max ){ 
      @content; 
  } 
} 

//media-queries的最小寬度
@mixin screen_min($res-min){ 
  @media screen and ( min-width: $res-min ){ 
      @content; 
  } 
}
//media-queries的最小最大寬度
@mixin screen_and($res-min, $res-max){ 
  @media screen and ( min-width: $res-min ) and ( max-width: $res-max ){ 
      @content; 
  } 
} 


//------------------------- px轉rem -------------------------//

 @function pxToEm($px, $base: 16) { 
  @return ($px / $base) * 1rem; 
}

//------------------------- 限定行數  行高 行數(行高x行數)x1rem ---------//
@mixin clamp($lh,$clamp){
  height: ($lh*$clamp) * 1em;
  line-height: $lh;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $clamp; /* 第幾行要顯示點點點 */
  -webkit-box-orient: vertical;
}


// //------------------------- 區塊寬度設定-------------------------//

@mixin secction_width($width:90%, $max:1200px){
    width: $width;
    max-width:$max;
    margin: 0 auto;
}
//------------------------- triangle 三角形 方向 尺寸 顏色 -------------------------//

@mixin triangle($direction, $size, $borderColor ) { 
    content:""; 
    height: 0;
    width: 0; 
    @if $direction == top { 
      border-bottom:$size solid $borderColor; 
      border-left:$size dashed transparent; 
      border-right:$size dashed transparent; 
    } @else if $direction == right { 
      border-left:$size solid $borderColor; 
      border-top:$size dashed transparent; 
      border-bottom:$size dashed transparent; 
    } @else if $direction == bottom { 
      border-top:$size solid $borderColor; 
      border-left:$size dashed transparent; 
      border-right:$size dashed transparent; 
    } @else if $direction == left { 
      border-right:$size solid $borderColor; 
      border-top:$size dashed transparent; 
      border-bottom:$size dashed transparent; 
    } 
}
//------------------------- 字型設定 -------------------------//

// ( 字大小, 行高, 字距, 字重(預設) ,顏色(預設) )
@mixin fontset($px, $lheight, $lspac,$weight:400 ,$color:#000000) {
  font-size: pxToEm($px);
  line-height: $lheight;
  letter-spacing: $lspac + px;
  font-weight: $weight;
  color: $color;
}


