footer {
    padding: 0 180px;
    padding-top: 40px;
    padding-bottom: 52px;
    background-color: #fff;
    position: relative;
    z-index: 4;
    @media (max-width: 1500px) {
        padding-left: 50px;
        padding-right: 50px;
    }
    @media (max-width: 1200px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    @media (max-width: 767px) {
        padding-bottom: 68px;
        padding-top: 117px;
    }
    .t-bx {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 991px) {
            display: block;
        }
        .f-logo {
            width: 178px;
            @media (max-width: 991px) {
                width: 190px;
                margin: 0 auto;
            }
        }
        .link-bx {
            display: flex;
            @media (max-width: 991px) {
                justify-content: center;
                margin-top: 35px;
            }
            a {
                width: 40px;
                display: block;
                height: 40px;
                position: relative;
                @media (max-width: 991px) {
                    width: 45px;
                    height: 45px;
                }
                &:not(:last-child) {
                    margin-right: 15px;
                    @media (max-width: 991px) {
                        margin-right: 44px;
                    }
                }
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 0;
                    transition: 0.4s ease;
                }
                .unhov {
                    opacity: 1;
                }
                .hov {
                    opacity: 0;
                }
                @media (min-width: 768px) {
                    &:hover {
                        .unhov {
                            opacity: 0;
                        }
                        .hov {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .b-bx {
        display: flex;
        justify-content: space-between;
        margin-top: 23px;
        @media (max-width: 991px) {
            display: block;
            margin-top: 30px;
        }
        .l {
            ul {
                @media (max-width: 991px) {
                    max-width: 292px;
                    width: 100%;
                    margin: 0 auto;
                }
                @media (max-width: 450px) {
                    max-width: 100%;
                }
                li {
                    a {
                        @include fontset(16, 1.8, 0, 300, #444444);
                        display: flex;
                        @media (max-width: 767px) {
                            @include fontset(20, 1.5, 0, 300, #444444);
                        }

                        i {
                            min-width: 55px;
                            display: block;
                            @media (max-width: 767px) {
                                min-width: 60px;
                            }
                        }
                    }
                }
            }
        }
        .r {
            .menu-bx {
                @media (max-width: 991px) {
                    display: none;
                }
                ul {
                    display: flex;
                }
                li:not(:last-child) {
                    margin-right: 40px;
                    @media (max-width: 991px) {
                        margin-right: 15px;
                    }
                }
                a {
                    @include fontset(16, 1.5, 0, 300, #707070);
                }
            }
            .copyright {
                margin-top: 35px;
                @include fontset(14, 2, 0, 400, #cccccc);
                text-align: right;
                @media (max-width: 991px) {
                    text-align: center;
                    margin-top: 25px;
                }
                @media (max-width: 767px) {
                    font-size: pxToEm(12);
                }
                a {
                    @include fontset(14, 2, 0, 400, #cccccc);
                    position: relative;
                    padding-left: 9px;
                    margin-left: 7px;
                    @media (max-width: 767px) {
                        font-size: pxToEm(12);
                    }
                    &::before {
                        position: absolute;
                        content: "";
                        left: 0;
                        top: 3px;
                        width: 1px;
                        height: 12px;
                        background-color: #ccc;
                    }
                }
            }
        }
    }
}

.r-fixbtn.show {
    opacity: 1;
    pointer-events: all;
}
.r-fixbtn {
    position: fixed;
    right: 16px;
    top: 40%;
    transform: translateY(-50%);
    width: 150px;
    height: 150px;
    padding: 15px;
    border-radius: 50%;
    background: url(../images/btn_more-01.png);
    z-index: 80;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s ease;
    @media (max-width: 767px) {
        bottom: 0;
        right: 0;
        width: 100%;
        height: 54px;
        background: none;
        border-radius: 0;
        bottom: 0;
        top: auto;
        transform: none;
        padding: 0;
        transition: 0s;
        box-shadow: 0 0 12px rgba(#707070, 0.31);
    }
    .tx-bx {
        background-color: $hover;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        text-align: center;
        @media (max-width: 767px) {
            border-radius: 0;
        }
    }
    .center-bx {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        @media (max-width: 767px) {
            top: auto;
            transform: none;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }
    .img-bx {
        margin-bottom: 5px;
        @media (max-width: 767px) {
            width: 25px;
            margin-bottom: 0;
            margin-right: 14px;
        }
    }
    .tx {
        @include fontset(20, 1.5, 1, 300, #fff);
        @media (max-width: 767px) {
            font-weight: 700;
        }
    }
    @media (min-width: 768px) {
        &:hover {
            opacity: 0.8;
        }
    }
}
