@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Noto+Sans+TC:wght@300;400;500;700&display=swap');
$font_content: 'Noto Sans TC';
$lato: 'Lato';




//-------------------------文字顏色-------------------------//
$black:#000;
$white:#ffffff;
$blue:#4759d9;
$orange : #de4242;
$hover:#FCAD28;
$main_color:#82D1CB; //主要顏色