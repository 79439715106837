.indexPage {
    .ind-ti {
        text-align: center;
        .ti {
            @include fontset(20, 1.5, 4, 400, $hover);
            margin-bottom: 0px;
            @media (max-width: 991px) {
                font-size: pxToEm(20);
                margin-bottom: 18px;
            }
        }
        .e-ti {
            @include fontset(124, 1.1, 0, 300, #979797);
            margin-top: -5px;
            position: relative;
            padding-bottom: 85px;
            @media (max-width: 991px) {
                font-size: pxToEm(40);
                font-weight: 700;
                padding-bottom: 45px;
            }
            &::before {
                position: absolute;
                content: "";
                left: 50%;
                transform: translateX(-50%);
                bottom: 0px;
                width: 1px;
                height: 80px;
                background-color: $hover;
                @media (max-width: 991px) {
                    height: 40px;
                }
            }
        }
        .sh-des {
        }
    }
    .banner-bx {
        position: relative;
        z-index: 3;
        position: relative;
        padding-bottom: 37.5%;
        @media (max-width: 767px) {
            padding-bottom: 93.75%;
        }
        a {
            display: block;
        }
        li.chbanner {
            .bgcover {
                opacity: 1;
            }
        }
        .bgcover {
            padding-bottom: 37.5%;
            background-size: cover !important;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: 0.4s ease;
            @media (max-width: 767px) {
                padding-bottom: 93.75%;
            }
        }
        .show-box {
            position: absolute;
            bottom: 19px;
            right: 166px;
            z-index: 10;
            display: flex;
            width: 159px;
            justify-content: space-between;
            @media (max-width: 1200px) {
                right: 80px;
                bottom: 10px;
            }
            @media (max-width: 767px) {
                bottom: 3px;
                right: 10px;
                width: 125px;
            }
            @keyframes lineani {
                0% {
                    width: 0;
                }
                100% {
                    width: 100%;
                }
            }
            .ani.line {
                &::before {
                    animation: lineani 5s linear forwards;
                }
            }
            .line {
                width: 80px;
                height: 1px;
                background-color: rgba(255, 255, 255, 0.5);
                margin: 0 auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @media (max-width: 768px) {
                    width: 57px;
                }
                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 0;
                    height: 1px;
                    background-color: #fff;
                }
            }
            .indxnum,
            .nexindx {
                position: relative;
                width: 40px;
                height: 40px;
            }
            a {
                @include fontset(14, 3, 1.8, 400, #ffffff);
                transition: 0.4s;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: block;
                width: 40px;
                height: 40px;
                text-align: center;
                @media (max-width: 767px) {
                    font-size: pxToEm(12);
                    letter-spacing: 0;
                    line-height: 3.3;
                }
                opacity: 0;
                pointer-events: none;
            }
            a.show-me {
                opacity: 1;
                pointer-events: auto;
            }
            .indxnum a {
                @media (max-width: 767px) {
                    margin-right: 3px;
                }
            }
            .nexindx a {
                color: rgba(255, 255, 255, 0.5);
                @media (min-width: 768px) {
                    &:hover {
                        color: #ffffff;
                    }
                }
                @media (max-width: 767px) {
                    margin-left: 3px;
                }
            }

            // .go-it{
            //     width: 43px;
            //     height: 20px;
            //     cursor: pointer;
            //     position: relative;
            //     &:not(:last-child()){
            //         margin-right: 20px;
            //     }
            //     @media (max-width:767px) {
            //         width: 26px;
            //     }
            //     &:before{
            //         width: 43px;
            //         height: 2px;
            //         background: #898989;
            //         content: '';
            //         position: absolute;
            //         top: 50%;
            //         left: 50%;
            //         transform: translate(-50%,-50%);
            //         @media (max-width:767px) {
            //             width: 35px;
            //             height: 2px;
            //         }
            //     }
            // }
        }
    }
    .item1 {
        padding-top: 120px;
        @media (max-width: 767px) {
            padding-top: 45px;
        }
        ul {
            margin-top: 70px;
            @media (max-width: 991px) {
                margin-top: 50px;
            }
            .sh-dotbtn span:before {
                @media (max-width: 400px) {
                    right: 15px;
                }
                @media (max-width: 350px) {
                    right: 0px;
                    width: calc(43.5% + 15px);
                }
            }
            .sh-dotbtn span::after {
                @media (max-width: 400px) {
                    right: 15px;
                }
                @media (max-width: 350px) {
                    right: 0px;
                }
            }
        }
    }
    .item2 {
        padding-top: 120px;
        .it2-bx {
            @media (min-width: 1200px) {
                max-width: 1592px;
            }
        }
        ul {
            margin-top: 62px;
            li {
                display: flex;
                align-items: center;
                position: relative;
                padding: 80px 0;
                &:not(:last-child) {
                    margin-bottom: 30px;
                    @media (max-width: 767px) {
                        margin-bottom: 54px;
                    }
                }
                @media (max-width: 767px) {
                    display: block;
                    padding-top: 0;
                    padding-bottom: 50px;
                }
                .bg {
                    width: 81%;
                    height: 100%;
                    background-color: $main_color;
                    z-index: -1;
                    @media (max-width: 767px) {
                        width: 100vw;
                        right: auto;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        height: 82%;
                    }
                }
                .img-bx {
                    // width: 48.4%;
                    width: 771px;
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
                .des-bx {
                    max-width: 390px;
                    width: 100%;
                    @media (max-width: 767px) {
                        max-width: 100%;
                        margin-top: 30px;
                    }
                    .ti {
                        @include fontset(48, 1.4, 0, 300, #fff);
                        @media (max-width: 767px) {
                            margin-bottom: -5px;
                        }
                    }
                    .ti-des {
                        @include fontset(24, 1.4, 2, 300, #fff);
                        position: relative;
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                        &::before {
                            position: absolute;
                            content: "";
                            left: 0;
                            bottom: 0;
                            width: 48px;
                            height: 1px;
                            background-color: #fff;
                        }
                    }
                    .sh-des {
                        color: #fff;
                        max-height: 7.5em;
                        overflow: hidden;
                        @media (max-width: 767px) {
                            max-height: 100%;
                        }
                    }
                }
                .bgcover {
                    background-size: cover !important;
                    padding-bottom: 62.5%;
                }
            }
            li:nth-child(odd) {
                justify-content: flex-start;
                .bg {
                    position: absolute;
                    right: -171px;
                    &::before {
                        position: absolute;
                        content: "";
                        left: 842px;
                        bottom: 0;
                        width: 426px;
                        height: 100%;
                        background: url(../images/point.png) no-repeat center center / contain;
                        @media (max-width: 767px) {
                            left: auto;
                            right: -35px;
                            top: -25px;
                            width: 120px;
                            height: 220px;
                        }
                    }
                }
                .img-bx {
                    margin-right: 7.6%;
                    @media (max-width: 767px) {
                        margin-right: 0;
                    }
                }
            }
            li:nth-child(even) {
                justify-content: flex-end;
                .bg {
                    position: absolute;
                    left: -171px;
                    @media (max-width: 767px) {
                        left: 50%;
                    }
                    &::before {
                        position: absolute;
                        content: "";
                        right: 842px;
                        bottom: 0;
                        width: 426px;
                        height: 100%;
                        background: url(../images/point_1.png) no-repeat center center / contain;
                        @media (max-width: 767px) {
                            background: url(../images/point.png) no-repeat center center / contain;
                            left: auto;
                            right: -35px;
                            top: -25px;
                            width: 120px;
                            height: 220px;
                        }
                    }
                }
                .img-bx {
                    margin-left: 7.6%;
                    order: 2;
                    @media (max-width: 767px) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    .item3 {
        padding-top: 130px;
        .ind-ti {
            text-align: left;
            .e-ti {
                padding-bottom: 0px;
                &::before {
                    display: none;
                }
            }
            .sh-des {
                padding-left: 176px;
                position: relative;
                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 160px;
                    height: 1px;
                    background-color: $hover;
                }
            }
        }
        ul.showall {
            li {
                display: block !important;
            }
        }
        ul {
            margin-top: 70px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            li {
                width: calc((100% - 32px) / 3);
                position: relative;
                padding: 20px;
                background: url(../images/btn_more-01.png);
                margin-bottom: 35px;
                // &:not(:last-child){
                //     margin-bottom: 35px;
                // }
                display: none;
                @media (max-width: 991px) {
                    width: calc((100% - 24px) / 2);
                }
                @media (max-width: 767px) {
                    width: 100%;
                    padding: 17px;
                    &:not(:last-child) {
                        margin-bottom: 52px;
                    }
                }
                &:nth-child(1) {
                    display: block;
                }
                &:nth-child(2) {
                    display: block;
                }
                &:nth-child(3) {
                    display: block;
                }
                @media (min-width: 768px) {
                    &:hover {
                        .hov-bx {
                            opacity: 1;
                        }
                    }
                }
                @media (min-width: 992px) {
                    &:not(:nth-child(3n)) {
                        margin-right: 16px;
                    }
                }
                @media (max-width: 991px) {
                    &:not(:nth-child(even)) {
                        margin-right: 24px;
                    }
                }
                @media (max-width: 767px) {
                    &:not(:nth-child(even)) {
                        margin-right: 0px;
                    }
                }

                .img-bx {
                    position: relative;
                    &::before {
                        position: absolute;
                        content: "";
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 220px;
                        background-image: linear-gradient(
                            to top,
                            #171613,
                            rgba(255, 255, 255, 0),
                            rgba(255, 255, 255, 0)
                        );
                    }
                    .bgcover {
                        padding-bottom: 133%;
                        background-size: cover !important;
                        @media (max-width: 767px) {
                            padding-bottom: 93%;
                        }
                    }
                    .des-bx {
                        position: absolute;
                        bottom: 22px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: calc(100% - 44px);
                        .ti {
                            display: flex;
                            padding-bottom: 14px;
                            margin-bottom: 15px;
                            border-bottom: 1px solid #fff;
                            align-items: flex-end;
                            .na {
                                @include fontset(26, 1.4, 1, 300, #fff);
                            }
                            .des {
                                margin-left: 8px;
                                @include fontset(16, 1.8, 1, 300, #fff);
                            }
                        }
                        .sh-des {
                            color: #fff;
                        }
                    }
                }
                .hov-bx {
                    opacity: 0;
                    transition: 0.4s ease;
                    position: absolute;
                    width: calc(100% - 40px);
                    height: calc(100% - 40px);
                    top: 20px;
                    left: 20px;
                    background-color: $hover;
                    padding: 25px 22px;
                    @media (max-width: 767px) {
                        opacity: 1;
                        position: relative;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: auto;
                        padding: 16px 30px 26px 30px;
                    }
                    .ti {
                        display: flex;
                        padding-bottom: 14px;
                        margin-bottom: 15px;
                        border-bottom: 1px solid #fff;
                        align-items: flex-end;
                        @media (max-width: 767px) {
                            display: none;
                        }
                        .na {
                            @include fontset(26, 1.4, 1, 300, #fff);
                        }
                        .des {
                            margin-left: 8px;
                            @include fontset(16, 1.8, 1, 300, #fff);
                        }
                    }
                    .ed-bx {
                        min-height: 253px;
                        position: relative;
                        overflow: hidden;
                        margin-bottom: 15px;
                        @media (max-width: 767px) {
                            min-height: 100%;
                            margin-bottom: 17px;
                        }
                        .editor_Content {
                            color: #fff;
                            @media (max-width: 767px) {
                                font-size: pxToEm(20);
                            }
                        }
                    }
                    .sh-dotbtn {
                        background: url(../images/btn_more-02.png);
                        .ins-bx {
                            background: $hover;
                        }
                        span {
                            color: #fff;
                            &::before {
                                background-color: #fff;
                                right: 0;
                                width: 57.5%;
                                @media (max-width: 767px) {
                                    width: 52.5%;
                                }
                            }
                            &::after {
                                background-color: #fff;
                                right: 0;
                            }
                        }
                    }
                }
            }
        }
        .hid.it3-morebtn {
            display: none;
        }
        .it3-morebtn {
            width: 252px;
            height: 64px;
            background: $main_color;
            text-align: center;
            display: block;
            margin: 0 auto;
            @media (max-width: 767px) {
                width: 170px;
                height: 46px;
                margin-top: -15px;
            }
            span {
                @include fontset(16, 3.9, 2, 300, #fff);
                font-family: $font_content;
                @media (max-width: 767px) {
                    line-height: 2.9;
                }
            }
        }
    }
    .item4 {
        position: relative;
        margin-top: 160px;
        padding: 80px 0;
        @media (max-width: 991px) {
            margin-top: 60px;
            padding: 40px 0;
        }
        .bg {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 71.25vw;
            background: $main_color;
            z-index: 4;
            @media (max-width: 991px) {
                width: 100%;
                height: 70%;
            }
            &::before {
                position: absolute;
                content: "";
                bottom: 0;
                right: 800px;
                width: 560px;
                height: 480px;
                background: url(../images/point_2.png) no-repeat center center / contain;
                @media (max-width: 991px) {
                    display: none;
                }
            }
        }
        .it4-bx {
            width: 100%;
            max-width: 1580px;
            display: flex;
            margin-left: auto;
            position: relative;
            z-index: 5;
            padding-left: 15px;
            @media (max-width: 991px) {
                display: block;
                padding: 0 25px;
            }
            .ind-ti {
                @media (min-width: 992px) {
                    text-align: left;
                    width: calc(100% - 745px);
                    padding-right: 15px;
                    @media (max-width: 1200px) {
                        width: calc(100% - 600px);
                    }
                    .ti {
                        color: #fff;
                    }
                    .e-ti {
                        padding-bottom: 0px;
                        color: #fff;
                        font-size: pxToEm(100);
                        @media (max-width: 1500px) {
                            font-size: pxToEm(75);
                        }
                        @media (max-width: 1199px) {
                            font-size: pxToEm(40);
                        }
                        &::before {
                            display: none;
                        }
                    }
                    .sh-des {
                        padding-left: 176px;
                        position: relative;
                        color: #fff;
                        @media (max-width: 1500px) {
                            padding-left: 116px;
                        }
                        &::before {
                            position: absolute;
                            content: "";
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 160px;
                            height: 1px;
                            background-color: #fff;
                            @media (max-width: 1500px) {
                                width: 100px;
                            }
                        }
                    }
                }
                @media (max-width: 991px) {
                    .ti {
                        color: #fff;
                    }
                    .e-ti {
                        color: #fff;
                        &::before {
                            background-color: #fff;
                            bottom: -6px;
                        }
                    }
                    .sh-des {
                        color: #fff;
                    }
                }
            }
            ul {
                width: 745px;
                @media (max-width: 1200px) {
                    width: 600px;
                }
                @media (max-width: 991px) {
                    width: 100%;
                    margin-top: 44px;
                }
                a {
                    display: block;
                    position: relative;
                    &::before {
                        position: absolute;
                        content: "";
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 65px;
                        background-image: linear-gradient(
                            0deg,
                            rgba(#444444, 0.6),
                            hsla(0, 0%, 100%, 0),
                            hsla(0, 0%, 100%, 0)
                        );
                    }
                    .ti {
                        position: absolute;
                        bottom: 15px;
                        left: 25px;
                        @include fontset(20, 1.5, 1, 400, #fff);
                    }
                    .bgcover {
                        width: 400px;
                        padding-bottom: 320px;
                        background-size: cover !important;
                        @media (max-width: 1200px) {
                            width: 330px;
                            padding-bottom: 230px;
                        }
                        @media (max-width: 991px) {
                            width: 100%;
                            padding-bottom: 80%;
                        }
                    }
                }
                .slick-prev {
                    width: 64px;
                    height: 64px;
                    background-color: $main_color;
                    transition: 0.4s ease;
                    top: auto;
                    transform: none;
                    bottom: 0;
                    left: -174px;
                    border: 1px solid #fff;
                    @media (max-width: 767px) {
                        width: 40px;
                        height: 40px;
                        bottom: auto;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0px;
                    }
                    &::before,
                    &::after {
                        position: absolute;
                        content: "";
                        width: 1px;
                        height: 20px;
                        background-color: #fff;
                        left: 50%;
                        transition: 0.4s ease;
                        @media (max-width: 767px) {
                            height: 16px;
                        }
                    }
                    &::before {
                        top: 17px;
                        transform: translateX(-50%) rotate(45deg);
                        @media (max-width: 767px) {
                            top: 6px;
                        }
                    }
                    &::after {
                        top: 31px;
                        transform: translateX(-50%) rotate(-45deg);
                        @media (max-width: 767px) {
                            top: 17px;
                        }
                    }
                    @media (min-width: 768px) {
                        &:hover {
                            background-color: #fff;
                            &::before,
                            &::after {
                                background-color: $main_color;
                            }
                        }
                    }
                }
                .slick-next {
                    width: 64px;
                    height: 64px;
                    background-color: $main_color;
                    transition: 0.4s ease;
                    top: auto;
                    right: auto;
                    transform: none;
                    bottom: 0;
                    left: -110px;
                    border: 1px solid #fff;
                    border-left: none;
                    @media (max-width: 767px) {
                        width: 40px;
                        height: 40px;
                        bottom: auto;
                        left: auto;
                        top: 50%;
                        right: 0px;
                        transform: translateY(-50%);
                        border-left: 1px solid #fff;
                    }
                    &::before,
                    &::after {
                        position: absolute;
                        content: "";
                        width: 1px;
                        height: 20px;
                        background-color: #fff;
                        left: 50%;
                        transition: 0.4s ease;
                        @media (max-width: 767px) {
                            height: 16px;
                        }
                    }
                    &::before {
                        top: 17px;
                        transform: translateX(-50%) rotate(-45deg);
                        @media (max-width: 767px) {
                            top: 6px;
                        }
                    }
                    &::after {
                        top: 31px;
                        transform: translateX(-50%) rotate(45deg);
                        @media (max-width: 767px) {
                            top: 17px;
                        }
                    }
                    @media (min-width: 768px) {
                        &:hover {
                            background-color: #fff;
                            &::before,
                            &::after {
                                background-color: $main_color;
                            }
                        }
                    }
                }
            }
        }
    }
    .item5 {
        padding-top: 160px;
        @media (max-width: 991px) {
            padding-top: 0px;
        }
        .map-bx {
            width: 100%;
            height: 520px;
            position: relative;
            z-index: 3;
            @media (max-width: 991px) {
                height: 270px;
            }
            iframe {
                width: 100%;
                height: 100%;
            }
        }
        .it5-bx {
            display: flex;
            justify-content: space-between;
            max-width: 1024px;
            width: 100%;
            margin: 0 auto;
            margin-top: 60px;
            @media (max-width: 991px) {
                display: block;
                margin-top: 40px;
            }
            .t-bx {
                margin-bottom: 45px;
                @media (max-width: 991px) {
                    margin-bottom: 20px;
                }
                .e-ti {
                    @include fontset(48, 1.2, 0, 300, $main_color);
                    @media (max-width: 991px) {
                        font-size: pxToEm(32);
                    }
                }
                .ti {
                    @include fontset(24, 1.2, 2, 300);
                    position: relative;
                    padding-bottom: 20px;
                    @media (max-width: 991px) {
                        margin-top: 3px;
                        font-size: pxToEm(20);
                        padding-bottom: 16px;
                    }
                    &::before {
                        position: absolute;
                        content: "";
                        bottom: 0;
                        left: 0;
                        width: 40px;
                        height: 1px;
                        background-color: $main_color;
                    }
                }
            }
            .l {
                width: 49%;
                padding-right: 5%;
                @media (max-width: 991px) {
                    width: 100%;
                    padding-right: 0;
                }
            }
            .r {
                width: 49%;
                @media (max-width: 991px) {
                    width: 100%;
                    margin-top: 38px;
                }
            }
        }
    }
    .item6 {
        margin-top: 50px;
        padding-top: 123px;
        padding-bottom: 123px;
        background: url(../images/bg_1.jpg) no-repeat center center / cover;
        @media (max-width: 767px) {
            padding-top: 64px;
            padding-bottom: 63px;
            background: url(../images/bg_mb.jpg) no-repeat center center / cover;
        }
        .it6-bx {
            .ind-ti {
                .ti {
                    color: #fff;
                }
                .e-ti {
                    color: #fff;
                    font-size: pxToEm(100);
                    &::before {
                        background-color: #fff;
                    }
                    @media (max-width: 767px) {
                        font-size: pxToEm(40);
                    }
                }
                .sh-des {
                    color: #fff;
                }
            }
            .flex-bx {
                display: flex;
                justify-content: space-between;
                margin-top: 55px;
                @media (max-width: 767px) {
                    margin-top: 23px;
                    display: block;
                }
                .sh-dotbtn {
                    max-width: 49%;
                    height: 120px;
                    transition: 0.4s ease;
                    @media (max-width: 767px) {
                        max-width: 100%;
                        height: 60px;
                    }
                    @media (min-width: 768px) {
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                    &:nth-child(1) {
                        @media (max-width: 767px) {
                            margin-bottom: 10px;
                        }
                        .ins-bx {
                            background: $hover;
                        }
                    }
                    &:nth-child(2) {
                        background: url(../images/btn_more-03.png);
                        .ins-bx {
                            background: $main_color;
                        }
                    }
                    span {
                        text-align: center;
                        line-height: 0.3;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            margin-right: 45px;
                            @media (max-width: 767px) {
                                width: 24px;
                                margin-right: 28px;
                            }
                        }
                        &::before,
                        &::after {
                            display: none;
                        }
                        @include fontset(24, 1.5, 13, 300, #fff);
                        @media (max-width: 767px) {
                            font-size: pxToEm(16);
                        }
                    }
                }
            }
        }
    }
}
