/*----------------------header------------------------*/

.header_show {
    transition: 0.4s;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 100;
    box-shadow: 0 4px 8px rgba(#000000, 0.1);
    li {
        list-style: none;
    }
    &.active {
        background: rgba(#131314, 1);
    }
}

.header_box {
    position: relative;
    width: 100%;
    height: 80px;
    padding: 0 180px;
    padding-right: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: pxToEm(14);
    @media (max-width: 1600px) {
        padding: 0 100px;
        padding-right: 200px;
    }
    @media (max-width: 1500px) {
        padding: 0 50px;
        padding-right: 200px;
    }
    @media (max-width: 1200px) {
        padding: 0 20px;
        padding-right: 200px;
    }
}

.logo {
    a {
        display: block;
    }
    width: 400px;
    .mobile {
        display: none;
    }
    @media (max-width: 1366px) {
        width: 280px;
    }
    @media (max-width: 1200px) {
        width: 190px;
        .desk {
            display: none;
        }
        .mobile {
            display: block;
        }
    }
}

/*----------------------nav------------------------*/

.nav_box {
    position: relative;
    display: flex;
    height: 100%;
    ul {
        display: flex;
        height: 100%;
        li {
            position: relative;
            text-align: center;
            margin-right: 40px;
            @media (max-width: 1440px) {
                margin-right: 15px;
            }
        }
    }
    li > a,
    .lan-bx > a {
        width: 100%;
        height: 100%;
        display: block;
        color: #707070;
        font-size: pxToEm(16);
        position: relative;
        @media (min-width: 768px) {
            &:hover {
                color: $hover;
                &::before {
                    opacity: 1;
                }
            }
        }
        span {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
        &::before {
            transition: 0.4s;
            position: absolute;
            content: "";
            width: 100%;
            height: 1px;
            background: $hover;
            bottom: 0px;
            left: 0px;
            opacity: 0;
        }
        &:hover {
            &:after {
                width: 77%;
                opacity: 1;
            }
            .en {
                opacity: 0;
            }
            .tw {
                opacity: 1;
            }
        }
    }
    .lan-bx {
        display: flex;
        a.active {
            color: #fff;
            background-color: $main_color;
        }
        a {
            width: 80px;
            display: block;
            text-align: center;
            font-size: pxToEm(14);
            color: $main_color;
            transition: 0.4s ease;
            span {
                display: block;
            }
            &::before {
                display: none;
            }
            @media (min-width: 768px) {
                &:hover {
                    color: #fff;
                    background-color: $main_color;
                }
            }
        }
    }
}

/*----------------------nav icon------------------------*/

.menu-wrapper {
    display: none;
}

$bar-width: 24px;
$bar-height: 2px;
$bar-spacing: 10px;
$bar-color: #707070;
.menu-wrapper {
    display: none;
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    margin: auto;
    width: $bar-width;
    height: $bar-height + $bar-spacing * 2;
    cursor: pointer;
    z-index: 10;
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
    width: $bar-width;
    height: $bar-height;
}

.hamburger-menu {
    position: relative;
    transform: translateY($bar-spacing);
    background: $bar-color;
    transition: all 0ms 300ms;
    &.animate {
        background: rgba(255, 255, 255, 0);
    }
}

.hamburger-menu:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: $bar-spacing;
    background: $bar-color;
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu:after {
    content: "";
    position: absolute;
    left: 0;
    top: $bar-spacing;
    background: $bar-color;
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:after {
    top: 0;
    transform: rotate(45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:before {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

//------------------------- Menu icon -------------------------//
.menu-icon {
    display: none;
    top: 45%;
    right: -0.7em;
    z-index: 100;
    width: 45px;
    height: 39px;
    margin-top: 0;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    position: absolute;
    @media (max-width: $breakpoint-tablet) {
        display: block;
    }
}

//------------------------- Mobie Menu icon -------------------------//
.menu-icon-block {
    display: none;
    position: relative;
    height: 100%;
    margin: auto;
    i {
        position: absolute;
        display: block;
        margin: auto;
        width: 72%;
        height: 2px;
        background-color: $white;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-border-radius: 99em;
        -moz-border-radius: 99em;
        border-radius: 99em;
        &.i1 {
            top: 0%;
        }
        &.i2 {
            top: 25%;
        }
        &.i3 {
            top: 51%;
        }
    }
}

@media (max-width: 991px) {
    $header_heright: 60px;

    .logo {
        width: 190px;
    }
    .header_show {
        background: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    .header_box {
        width: 100%;
        height: 60px;
        font-size: pxToEm(17);
        margin: 0 auto;
        padding: 0;
        padding: 0 20px;
    }
    .nav_box nav {
        width: 100%;
        margin: auto;
        top: calc(50% - 37px);
        transform: translateY(-50%);
        position: relative;
    }
    .nav_box {
        display: none;
        position: fixed;
        top: $header_heright;
        left: 0;
        width: 100%;
        height: calc(100% - 60px);
        z-index: 11;
        // background: #fff;
        background: url(../images/btn_dot.jpg) no-repeat center / cover;
        background-color: #fff;
        ul {
            display: block;
            li {
                width: 100%;
                a {
                    font-size: pxToEm(26);
                    padding: 23px 15px;
                    text-align: center;
                    text-decoration: none;
                    position: relative;
                    font-weight: 300;
                    color: black;
                    &::before {
                        position: absolute;
                        content: "";
                        bottom: -14px;
                        display: none;
                    }
                    span {
                        top: auto;
                        transform: none;
                    }
                }
            }
        }
        ul > li:not(:last-child) {
            margin-right: 0;
        }
        .lan-bx {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            a {
                width: 33.33333%;
                height: 75px;
                background-color: $main_color;
                color: #fff;
                &:not(:last-child) {
                    border-right: 1px solid #fff;
                }
            }
        }
    }
    .menu-wrapper {
        display: block;
    }
}
//------------------------- Menu search -------------------------//
.search {
    position: absolute;
    right: -185px;
    top: 22px;
    .box {
        width: 175px;
        border-radius: 10px;
        border: 1px solid #82d1cb;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 1250px) {
            width: 160px;
        }
        @media (max-width: 991px) {
            width: 88%;
            margin: 0 auto;
        }
    }
    input {
        flex-grow: 1;
        border-radius: 10px;
        height: 40px;
        letter-spacing: 0.05em;
        padding: 0 10px;
        font-family: $font_content;
        &::placeholder {
            font-family: $font_content;
            font-weight: 300;
            letter-spacing: 0.05em;
            color: #707070;
        }
        &:focus {
            border: none;
        }
    }
    .line {
        width: 1px;
        height: 20px;
        background-color: #82d1cb;
    }
    a {
        width: 50px;
        padding: 0 8px;
    }
}

@media (max-width: 991px) {
    .search {
        width: 100%;
        padding-top: 25px;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #ffffff;
        z-index: 50;
        input {
            font-size: pxToEm(26);
        }
    }
}
