.teamPage {
    .container {
        @media (min-width: 1200px) {
            max-width: 1056px;
        }
    }
    .item1 {
        padding-top: 100px;
        padding-bottom: 100px;
        @media (max-width: 991px) {
            padding-top: 50px;
            padding-bottom: 50px;
        }
        .it1-bx {
            display: flex;
            justify-content: space-between;
            @media (max-width: 991px) {
                display: block;
            }
            .t-img {
                order: 2;
                width: 356px;
                @media (max-width: 991px) {
                    margin: 0 auto;
                }
                @media (max-width: 767px) {
                    width: 100%;
                }
                .bgcover {
                    padding-bottom: 472px;
                    background-size: cover !important;
                    @media (max-width: 767px) {
                        padding-bottom: 132%;
                    }
                }
            }
            .b-bx {
                width: calc(100% - 356px);
                padding-right: 10%;
                @media (max-width: 991px) {
                    width: 100%;
                    padding-right: 0;
                    margin-top: 22px;
                }
                .ti-bx {
                    display: flex;
                    padding-bottom: 15px;
                    position: relative;
                    @media (max-width: 991px) {
                        padding-bottom: 10px;
                    }
                    &::before {
                        position: absolute;
                        content: "";
                        bottom: 0;
                        left: 0;
                        width: 40px;
                        height: 1px;
                        background-color: $main_color;
                        @media (max-width: 991px) {
                            width: 20px;
                        }
                    }
                    div {
                        @include fontset(24, 1.3, 1, 400);
                    }
                }
                .editor_Content {
                    margin-top: 30px;
                    @media (max-width: 991px) {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    .item2 {
        padding-top: 100px;
        padding-bottom: 110px;
        background-color: #fbfbfb;
        @media (max-width: 991px) {
            padding-top: 50px;
            padding-bottom: 55px;
        }
        .sh-dotbtn {
            margin-top: 70px;
            @media (max-width: 991px) {
                margin-top: 30px;
            }
            @media (min-width: 768px) {
                &:hover {
                    .ins-bx {
                        background-color: $hover;
                    }
                    .ins-bx span {
                        color: #fff;
                        &::before {
                            // right: auto;
                            background-color: #fff;
                            left: 0;
                            width: calc(58% + 5px);
                        }
                        &::after {
                            background-color: #fff;
                            left: 0;
                        }
                    }
                }
            }
            .ins-bx {
                background-color: #fff;
                span {
                    color: $hover;
                    text-align: right;
                    padding-right: 40px;
                    @media (max-width: 767px) {
                        padding-right: 10px;
                    }
                    &::before,
                    &::after {
                        background-color: $hover;
                    }
                    &::before {
                        left: 40px;
                        right: auto;
                        @media (max-width: 767px) {
                            left: 0;
                            width: calc(58.5% + 30px);
                        }
                    }
                    &::after {
                        right: auto;
                        left: 40px;
                        @media (max-width: 767px) {
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}
.aboutPage {
    .container {
        @media (min-width: 1200px) {
            max-width: 1056px;
        }
    }
    .item1 {
        padding-top: 100px;
        padding-bottom: 130px;
        @media (max-width: 767px) {
            padding-top: 50px;
            padding-bottom: 65px;
        }
        ul {
            li:not(:last-child) {
                margin-bottom: 80px;
                @media (max-width: 767px) {
                    margin-bottom: 30px;
                }
            }
            .ti-bx {
                padding-top: 12px;
                padding-bottom: 16px;
                padding-left: 32px;
                padding-right: 40px;
                @include fontset(24, 1.4, 1, 400);
                box-shadow: 0 0 16px rgba(#000000, 0.1);
                position: relative;
                @media (max-width: 767px) {
                    font-size: pxToEm(20);
                    padding-left: 16px;
                    padding-top: 6px;
                    padding-bottom: 8px;
                    padding-right: 20px;
                    box-shadow: 0 0 8px rgba(#000000, 0.1);
                }
                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 5px;
                    height: calc(100% -20px);
                    background-color: $hover;
                    @media (max-width: 767px) {
                        width: 2px;
                    }
                }
            }
            .ed-bx {
                margin-top: 30px;
                @media (max-width: 767px) {
                    margin-top: 22px;
                }
            }
        }
    }
}

.htmlPage {
    .item1 {
        .container {
            @media (min-width: 1200px) {
                max-width: 1056px;
            }
        }
        padding-top: 100px;
        padding-bottom: 130px;
        @media (max-width: 767px) {
            padding-top: 50px;
            padding-bottom: 65px;
        }
        ul {
            li:not(:last-child) {
                margin-bottom: 80px;
                @media (max-width: 767px) {
                    margin-bottom: 30px;
                }
            }
            .ti-bx {
                padding-top: 12px;
                padding-bottom: 16px;
                padding-left: 32px;
                padding-right: 40px;
                @include fontset(24, 1.4, 1, 400);
                box-shadow: 0 0 16px rgba(#000000, 0.1);
                position: relative;
                @media (max-width: 767px) {
                    font-size: pxToEm(24);
                    padding-left: 16px;
                    padding-top: 6px;
                    padding-bottom: 8px;
                    padding-right: 20px;
                    box-shadow: 0 0 8px rgba(#000000, 0.1);
                }
                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 5px;
                    height: calc(100% -20px);
                    background-color: $hover;
                    @media (max-width: 767px) {
                        width: 2px;
                    }
                }
            }
            .ed-bx {
                margin-top: 30px;
                @media (max-width: 767px) {
                    margin-top: 22px;
                }
            }
        }
        .sh-dotbtn {
            margin-top: 65px;
            @media (max-width: 991px) {
                margin-top: 30px;
            }
            @media (min-width: 768px) {
                &:hover {
                    .ins-bx {
                        background-color: $hover;
                    }
                    .ins-bx span {
                        color: #fff;
                        &::before {
                            // right: auto;
                            background-color: #fff;
                            left: 0;
                            width: calc(58% + 5px);
                        }
                        &::after {
                            background-color: #fff;
                            left: 0;
                        }
                    }
                }
            }
            .ins-bx {
                background-color: #fff;
                span {
                    color: $hover;
                    text-align: right;
                    padding-right: 40px;
                    @media (max-width: 767px) {
                        padding-right: 10px;
                    }
                    &::before,
                    &::after {
                        background-color: $hover;
                    }
                    &::before {
                        left: 40px;
                        right: auto;
                        @media (max-width: 767px) {
                            left: 0;
                            width: calc(58.5% + 30px);
                        }
                    }
                    &::after {
                        right: auto;
                        left: 40px;
                        @media (max-width: 767px) {
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}

.servicePage {
    .item0 {
        padding-top: 100px;
        @media (max-width: 767px) {
            padding-top: 50px;
        }
    }
    .item1 {
        padding-top: 72px;
        @media (max-width: 767px) {
            padding-top: 35px;
        }
    }
}

.casedetailPage,
.newsdetailPage {
    .item1 ul .ed-bx {
        margin-top: 48px;
        @media (max-width: 767px) {
            margin-top: 30px;
        }
    }
}
.newsdetailPage {
    .item1 ul .ti-bx {
        .t-flex {
            display: flex;
            align-items: center;
            margin-bottom: 3px;
            @media (max-width: 767px) {
                margin-bottom: 5px;
            }
            .ty-ti {
                @include fontset(16, 1.5, 0.5, 400, $main_color);
            }
            .da {
                @include fontset(16, 1.5, 0.5, 300, #707070);
                padding-left: 33px;
                position: relative;
                &::before {
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 8px;
                    transform: translateY(-50%);
                    width: 16px;
                    height: 1px;
                    background-color: #707070;
                }
            }
        }
    }
}

.newsPage {
    .item0 {
        padding-top: 100px;
        @media (max-width: 767px) {
            padding-top: 50px;
        }
    }
    .item1 {
        padding-top: 72px;
        padding-bottom: 132px;
        @media (max-width: 767px) {
            padding-top: 25px;
            padding-bottom: 15px;
        }
        .container {
            @media (min-width: 1200px) {
                max-width: 1056px;
            }
        }
        .news-list {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            li {
                width: 45%;
                margin-bottom: 48px;
                @media (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 40px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                @media (min-width: 768px) {
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:nth-last-child(2) {
                        margin-bottom: 0;
                    }
                }
                a {
                    display: block;
                    @media (min-width: 768px) {
                        &:hover {
                            .ti {
                                color: $hover;
                            }
                        }
                    }
                    .t-flex {
                        display: flex;
                        align-items: center;
                        margin-bottom: 3px;
                        @media (max-width: 767px) {
                            margin-bottom: 5px;
                        }
                        .ty-ti {
                            @include fontset(16, 1.5, 0.5, 400, $main_color);
                        }
                        .da {
                            @include fontset(16, 1.5, 0.5, 300, #707070);
                            padding-left: 33px;
                            position: relative;
                            &::before {
                                position: absolute;
                                content: "";
                                top: 50%;
                                left: 8px;
                                transform: translateY(-50%);
                                width: 16px;
                                height: 1px;
                                background-color: #707070;
                            }
                        }
                    }
                    .ti {
                        @include fontset(20, 1.5, 0.5, 400);
                        transition: 0.4s ease;
                    }
                }
            }
        }
        .page {
            @media (max-width: 767px) {
                margin-top: 30px;
            }
        }
    }
}

.casePage {
    .item1 {
        padding-top: 100px;
        padding-bottom: 130px;
        @media (max-width: 767px) {
            padding-top: 50px;
            padding-bottom: 5px;
        }
        .serlist {
            display: flex;
            flex-wrap: wrap;
            li {
                width: calc((100% - 32px) / 3);
                padding: 0;
                margin-bottom: 70px;
                @media (max-width: 1199px) and (min-width: 992px) {
                    width: calc((100% - 20px) / 2);
                    &:not(:nth-child(even)) {
                        margin-right: 20px;
                    }
                }
                @media (max-width: 991px) {
                    width: 100%;
                }

                @media (max-width: 767px) {
                    margin-bottom: 35px;
                }

                @media (min-width: 1200px) {
                    &:not(:nth-child(3n)) {
                        margin-right: 16px;
                    }
                }
                .des-bx {
                    .t {
                        height: 30px;
                        position: relative;
                        overflow: hidden;
                    }
                    .sh-des {
                        @include clamp(1.5, 2);
                        margin-bottom: 3px;
                    }
                    .tag-bx {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        .tag {
                            @include fontset(16, 1.5, 0.5, 300, $main_color);
                            &:not(:last-child) {
                                margin-right: 6px;
                            }
                            @media (max-width: 767px) {
                                @include fontset(20, 1.5, 0.5, 300, $main_color);
                            }
                        }
                    }
                    .sh-dotbtn {
                        margin-top: 30px;
                        @media (max-width: 767px) {
                            margin-top: 25px;
                        }
                    }
                }
                .sh-dotbtn span:before {
                    @media (max-width: 400px) {
                        right: 15px;
                    }
                    @media (max-width: 350px) {
                        right: 0px;
                        width: calc(43.5% + 15px);
                    }
                }
                .sh-dotbtn span::after {
                    @media (max-width: 400px) {
                        right: 15px;
                    }
                    @media (max-width: 350px) {
                        right: 0px;
                    }
                }
            }
        }
        .page {
            margin-top: 10px;
        }
    }
}

.contactPage {
    .sh-banner .l-card .e-ti {
        @media (max-width: 767px) {
            font-size: pxToEm(27);
        }
    }
    .item1 {
        padding-top: 100px;
        @media (max-width: 767px) {
            padding-top: 50px;
        }
        .ti {
            padding-bottom: 100px;
            position: relative;
            text-align: center;
            @include fontset(20, 1.5, 2, 300);
            @media (max-width: 767px) {
                padding-bottom: 65px;
            }
            &::before {
                position: absolute;
                content: "";
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 1px;
                height: 80px;
                background-color: $hover;
                @media (max-width: 767px) {
                    height: 50px;
                }
            }
        }
        .form-bx {
            max-width: 816px;
            width: 100%;
            margin: 0 auto;
            margin-top: 15px;
            .form-group {
                margin-bottom: 24px;
            }
            label {
                display: flex;
                justify-content: flex-start;
                @include fontset(16, 1.5, 1, 300);
                margin-bottom: 4px;
                @media (max-width: 767px) {
                    @include fontset(20, 1.5, 1, 300);
                }
                i {
                    color: #ff0000;
                }
            }
            input {
                width: 100%;
                border-radius: 0;
                border: 1px solid #cccccc;
                border-radius: 0;
                height: 48px;
                padding-left: 20px;
                padding-right: 0;
            }
            .help-block {
                margin-top: 3px;
                @include fontset(14, 1.5, 1, 300, $hover);
            }
            .rad_content {
                display: flex;
                flex-wrap: wrap;
                position: relative;
                width: 100%;
                margin-top: 5px;
                @media (max-width: 767px) {
                    width: 100%;
                    margin-top: 10px;
                }
                input {
                    display: none;
                }
                label {
                    padding-left: 22px;
                    // margin-right: 15px;
                    margin-bottom: 0;
                    cursor: pointer;
                    width: auto;
                    min-width: auto;
                    @media (max-width: 767px) {
                        padding-left: 20px;
                        margin-bottom: 0;
                    }
                }
                input[type="radio"] + label:before {
                    width: 18px;
                    height: 18px;
                    background-color: #fff;
                    border: 1px solid #000;
                    border-radius: 100px;
                    position: absolute;
                    top: 3px;
                    left: 0;
                    content: "";
                    @media (max-width: 767px) {
                        width: 15px;
                        height: 15px;
                        top: 8px;
                    }
                }
                input[type="radio"]:checked + label:after {
                    width: 10px;
                    height: 10px;
                    background-color: $main_color;
                    border-radius: 100px;
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 4.5px;
                    transform: translateY(-50%);
                    @media (max-width: 767px) {
                        top: 15px;
                        left: 4px;
                        width: 7px;
                        height: 7px;
                    }
                }
                .radio {
                    position: relative;
                    margin-right: 0px;
                    &:not(:last-child) {
                        margin-right: 30px;
                    }
                    @media (max-width: 767px) {
                        width: auto;
                        margin-right: 0px;
                    }
                }
            }
            .textarea-bx {
                margin-top: -10px;
                display: block;
                textarea {
                    height: 335px;
                    border: 1px solid #cccccc;
                    padding-left: 20px;
                    padding-top: 12px;
                    border-radius: 0;
                    resize: none;
                    @media (max-width: 767px) {
                        height: 115px;
                    }
                }
            }
            .res-bx {
                padding-left: 22px;
                display: none;
                .form-group:first-child {
                    padding-bottom: 20px;
                    border-bottom: 1px solid #cccccc;
                }
                .form-group > label {
                    margin-bottom: 8px;
                }
                .othinp-bx {
                    display: flex;
                }
                .othinput {
                    width: 180px;
                    height: 23px;
                    border: none;
                    display: block;
                    border-bottom: 1px solid #cccccc;
                    padding-left: 5px;
                }
            }
            .send-btn {
                margin-top: 60px;
                @media (max-width: 767px) {
                    margin-top: 40px;
                }
                @media (min-width: 768px) {
                    &:hover {
                        span:before {
                            width: calc(77.5% - 40px);
                        }
                    }
                }
            }
        }
    }
    .item2 {
        padding-top: 120px;
        @media (max-width: 767px) {
            padding-top: 73px;
        }
        .it2-bx {
            position: relative;
            .t {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                max-width: 564px;
                padding: 43px 0;
                padding-left: 100px;
                padding-right: 15px;
                background-color: rgba(#ffffff, 0.85);
                box-shadow: 0 0 24px rgba(#000000, 0.15);
                @media (max-width: 991px) {
                    max-width: 450px;
                    padding-left: 50px;
                }
                @media (max-width: 767px) {
                    position: relative;
                    top: auto;
                    transform: none;
                    max-width: 100%;
                    padding-top: 25px;
                    padding-left: 30px;
                    padding-right: 30px;
                    padding-bottom: 25px;
                    box-shadow: 0 0 12px rgba(#000000, 0.15);
                }
                .ti {
                    @include fontset(24, 1.3, 2, 300);
                    padding-bottom: 15px;
                    margin-bottom: 25px;
                    position: relative;
                    @media (max-width: 767px) {
                        margin-bottom: 15px;
                        padding-bottom: 15px;
                        font-size: pxToEm(20);
                    }
                    &::before {
                        position: absolute;
                        content: "";
                        bottom: 0;
                        left: 0;
                        width: 40px;
                        height: 1px;
                        background-color: $main_color;
                        @media (max-width: 767px) {
                            width: 20px;
                        }
                    }
                }
                ul {
                    li {
                        padding-left: 55px;
                        position: relative;
                        &:not(:last-child) {
                            margin-bottom: 24px;
                            @media (max-width: 767px) {
                                margin-bottom: 10px;
                            }
                        }
                        @media (max-width: 767px) {
                            padding-left: 47px;
                        }
                        img {
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            position: absolute;
                            @media (max-width: 767px) {
                                width: 33px;
                                top: 8px;

                                transform: none;
                            }
                        }
                        i {
                            display: block;
                            @include fontset(16, 1.5, 0.25, 400, $main_color);
                            @media (max-width: 767px) {
                                @include fontset(18, 1.5, 0.25, 400, $main_color);
                            }
                        }
                        span {
                            @include fontset(16, 1.5, 0.25, 400);
                            @media (max-width: 767px) {
                                @include fontset(18, 1.5, 0.25, 400);
                            }
                        }
                    }
                }
            }
            .b {
                height: 640px;
                width: 66%;
                margin-left: auto;
                @media (max-width: 767px) {
                    width: 100%;
                    margin: 0 auto;
                    margin-top: 30px;
                    height: 270px;
                }
                @media (max-width: 575px) {
                    width: 100vw;
                    position: relative;
                    left: -25px;
                }
                .iframe-bx,
                iframe {
                    height: 100%;
                }
            }
        }
    }
    .item3 {
        padding-top: 70px;
        padding-bottom: 100px;
        position: relative;
        @media (max-width: 991px) {
            padding-top: 0px;
            padding-bottom: 50px;
        }
        .bg {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: calc(100% + 330px);
            background-color: #fbfbfb;
            @media (max-width: 767px) {
                height: calc(100% + 390px);
            }
        }
        .it3-bx {
            display: flex;
            justify-content: space-between;
            max-width: 1024px;
            width: 100%;
            margin: 0 auto;
            margin-top: 60px;
            @media (max-width: 991px) {
                display: block;
                margin-top: 30px;
            }
            .t-bx {
                margin-bottom: 45px;
                @media (max-width: 991px) {
                    margin-bottom: 20px;
                }
                .e-ti {
                    @include fontset(48, 1.2, 0, 300, $main_color);
                    @media (max-width: 991px) {
                        font-size: pxToEm(32);
                    }
                }
                .ti {
                    @include fontset(24, 1.2, 2, 300);
                    position: relative;
                    padding-bottom: 20px;
                    @media (max-width: 991px) {
                        margin-top: 3px;
                        font-size: pxToEm(20);
                        padding-bottom: 16px;
                    }
                    &::before {
                        position: absolute;
                        content: "";
                        bottom: 0;
                        left: 0;
                        width: 40px;
                        height: 1px;
                        background-color: $main_color;
                    }
                }
            }
            .l {
                width: 49%;
                padding-right: 5%;
                @media (max-width: 991px) {
                    width: 100%;
                    padding-right: 0;
                }
            }
            .r {
                width: 49%;
                @media (max-width: 991px) {
                    width: 100%;
                    margin-top: 38px;
                }
            }
        }
    }
}

.searchPage {
    .item0 {
        padding-top: 100px;
        .search-content {
            display: flex;
            align-items: center;
            padding: 0 88px;
            font-size: pxToEm(20);
            margin-bottom: 25px;
            .iconwrap {
                margin-right: 15px;
            }
            > div {
                color: #000;
                letter-spacing: 0.03em;
                &:last-child {
                    color: #82d1cb;
                }
            }
            @media (max-width: 767px) {
                padding: 0;
            }
        }
        #top-menu-ul {
            margin-bottom: 17px;
            .item_Menu {
                height: 48px;
            }
            li {
                height: 48px;
            }
            li a {
                font-size: pxToEm(20);
                color: #82d1cb;
                letter-spacing: 0.2em;
                font-weight: 300;
                border: 1px solid #82d1cb;
                padding: 5px 15px;
                margin: 0 15px;
                border-radius: 25px;
                span {
                    line-height: normal;
                }
            }
            li.active {
                a {
                    color: white;
                    background-color: #fcad28;
                    border: 1px solid #fcad28;
                }
            }
            li:hover {
                @media (min-width: 768px) {
                    a {
                        color: white;
                        background-color: #fcad28;
                        border: 1px solid #fcad28;
                    }
                }
            }
        }
        @media (max-width: 767px) {
            padding-top: 50px;
        }
    }
    .item1 {
        .container {
            border-top: 1px solid #cccccc;
            padding-top: 57px;
            @media (min-width: 1200px) {
                max-width: 1056px;
            }
        }
        .no-result {
            font-size: pxToEm(20);
            text-align: center;
        }
        .news-list {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 155px;
            li {
                width: 45%;
                margin-bottom: 48px;
                @media (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 40px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                @media (min-width: 768px) {
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:nth-last-child(2) {
                        margin-bottom: 0;
                    }
                }
                a {
                    display: block;
                    @media (min-width: 768px) {
                        &:hover {
                            .ti {
                                color: $hover;
                            }
                        }
                    }
                    .ti {
                        @include fontset(20, 1.5, 0.5, 400);
                        transition: 0.4s ease;
                    }
                }
            }
            @media (max-width: 767px) {
                margin-bottom: 50px;
            }
        }
    }
}
