/// 需要推的padding-bottom以及預設圖用當前頁指定到此calss
.bgcover {
    width: 100%;
    height: 0;
    background: 50% / cover;
}
.mo {
    display: none;
    @media (max-width: 767px) {
        display: block;
    }
}
.pc {
    display: none;
    @media (min-width: 768px) {
        display: block;
    }
}
.container {
    padding: 0 16px;
    margin: 0 auto;
    width: 100%;
    z-index: 3;
    position: relative;
    @media (max-width: 767px) {
        padding: 0 25px;
    }
    @media (min-width: 576px) {
        max-width: 540px;
    }
    @media (min-width: 768px) {
        max-width: 720px;
    }
    @media (min-width: 992px) {
        max-width: 960px;
    }
    @media (min-width: 1200px) {
        max-width: 1232px;
    }
}

// top-menu
#top-menu-ul {
    position: relative;
    z-index: 1;
    padding: 0 88px;
    @media (max-width: 767px) {
        padding: 0 55px;
    }
    .item_Menu {
        margin: 0 auto;
        overflow: hidden;
        height: 64px;
    }
    .item_menu_Box {
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;
        position: relative;
        text-align: left;
        .item_menu_list {
            white-space: nowrap !important;
            padding-bottom: 0px;
            text-align: center;
            font-size: 0px;
            display: inline-flex;
        }

        li {
            height: 64px;
            &:first-child {
                a {
                    margin-left: 0;
                }
            }
        }
        li a {
            position: relative;
            display: block;
            line-height: 2.7;
            letter-spacing: 0.6px;
            font-size: pxToEm(20);
            // color: #CCCCCC;
            color: #707070;
            @media (max-width: 767px) {
                padding: 7px 0 3px;
                margin: 0 20px 0 0;
                line-height: 2.5;
            }
            @media (min-width: 768px) {
                margin: 0 20px;
                padding: 7px 0;
                &:hover {
                    color: $hover;
                }
            }
        }
        li.active a {
            color: $hover;
        }
    }
}
.flex-direction-nav {
    display: none;
    a {
        width: 64px;
        height: 64px;
        background-color: $main_color;
        transition: 0.4s ease;
        display: block;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        z-index: 10;
        &:after {
            position: absolute;
            top: 0;
        }
        @media (max-width: 767px) {
            width: 36px;
            height: 36px;
        }
    }
    a.flex-next {
        // background: url(../svg/slick-next.svg) no-repeat center / 15px;
        right: 0;
        &::before,
        &::after {
            position: absolute;
            content: "";
            width: 1px;
            height: 20px;
            background-color: #fff;
            left: 50%;
            transition: 0.4s ease;
            @media (max-width: 767px) {
                height: 16px;
            }
        }
        &::before {
            top: 17px;
            transform: translateX(-50%) rotate(-45deg);
            @media (max-width: 767px) {
                top: 5px;
            }
        }
        &::after {
            top: 31px;
            transform: translateX(-50%) rotate(45deg);
            @media (max-width: 767px) {
                top: 16px;
            }
        }
        @media (min-width: 768px) {
            &:hover {
                background-color: #fff;
                &::before,
                &::after {
                    background-color: #cccccc;
                }
            }
        }
    }

    a.flex-prev {
        // background: url(../svg/slick-prev.svg) no-repeat center / 15px;
        left: 0px;
        &::before,
        &::after {
            position: absolute;
            content: "";
            width: 1px;
            height: 20px;
            background-color: #fff;
            left: 50%;
            transition: 0.4s ease;
            @media (max-width: 767px) {
                height: 16px;
            }
        }
        &::before {
            top: 17px;
            transform: translateX(-50%) rotate(45deg);
            @media (max-width: 767px) {
                top: 5px;
            }
        }
        &::after {
            top: 31px;
            transform: translateX(-50%) rotate(-45deg);
            @media (max-width: 767px) {
                top: 16px;
            }
        }
        @media (min-width: 768px) {
            &:hover {
                background-color: #fff;
                &::before,
                &::after {
                    background-color: #cccccc;
                }
            }
        }
    }
}
.open_flexslider {
    .flex-direction-nav {
        display: block;
    }
}

////   popup

//  .pop-act{
//     background: rgba(0, 0, 0, 0.8);
//     position: fixed;
//     width: 100%;
//     height: 100%;
//     z-index: 999;
//     display: none;
//     .vo_signbg{
//         background: url(../images/qr.png) no-repeat;
//         position: relative;
//         width: 300px;
//         height: 300px;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%,-50%);
//         @media (max-width:992px) {
//             background-size:450px;
//             background-position: center center;
//             width: 450px;
//         }
//         @media (max-width:576px) {
//             width: 320px;
//             background-size:320px;
//         }
//     }
//     .clos-btn{
//         width: 60px;
//         position: absolute;
//         top: -30px;
//         right: -90px;
//         border-radius: 99px;
//         display: block;
//         height: 20px;
//         cursor: pointer;
//         @media (max-width:576px) {
//             // top: 0px;
//             right: -20px;
//             width: 40px;
//             padding-bottom: 40px;
//         }
//         &:before{
//             content: '';
//             width: 30px;
//             height: 2px;
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             // transform: rotate(45deg);
//             transform: translate(-50%,-50%) rotate(45deg);
//             background: #ffffff;
//             @media (max-width:576px) {
//                 width: 15px;
//             }
//         }
//         &:after{
//             content: '';
//             width: 30px;
//             height: 2px;
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             // transform: rotate(45deg);
//             transform: translate(-50%,-50%) rotate(-45deg);
//             background: #ffffff;
//             @media (max-width:576px) {
//                 width: 15px;
//             }
//         }
//     }
// }
// .show-pop{
//     display: block !important;
//     }

#particles-js {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.sh-des {
    @include fontset(16, 1.5, 1, 300);
    @media (max-width: 767px) {
        @include fontset(20, 1.5, 1, 300);
    }
}
.sh-dotbtn {
    display: block;
    max-width: 312px;
    width: 100%;
    height: 80px;
    background: url(../images/btn_more-01.png);
    padding: 8px;
    margin: 0 auto;
    @media (max-width: 767px) {
        height: 60px;
        padding: 6px;
    }
    @media (min-width: 768px) {
        &:hover {
            .ins-bx {
                background: $hover;
            }
            span {
                color: #fff;
            }
            span:before {
                right: 0;
                width: calc(43.5% - 40px);
                background-color: #fff;
            }
            span:after {
                right: 0;
                background-color: #fff;
            }
        }
    }
    .ins-bx {
        background-color: #fff;
        width: 100%;
        height: 100%;
        padding: 0 30px;
        padding-left: 26px;
        transition: 0.4s ease;
        @media (max-width: 767px) {
            padding: 0 25px;
        }
    }
    span.jp {
        padding-right: 140px;
    }
    span {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        text-align: left;
        @include fontset(16, 1.5, 2, 300, $hover);
        transition: 0.4s ease;
        &::before {
            position: absolute;
            content: "";
            width: 43.5%;
            height: 1px;
            top: 50%;
            transform: translateY(-50%);
            right: 40px;
            background-color: $hover;
            transition: 0.4s ease;
            @media (max-width: 767px) {
                right: 30px;
                width: calc(43.5% + 30px);
            }
        }
        &::after {
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            top: 50%;
            transform: translateY(-50%);
            right: 40px;
            background-color: $hover;
            border-radius: 50%;
            transition: 0.4s ease;
            @media (max-width: 767px) {
                right: 30px;
            }
        }
    }
}
.serlist {
    position: relative;
    .slick-list {
        padding: 0 7px;
        padding-bottom: 7px;
        @media (max-width: 767px) {
            padding: 0;
            width: calc(100% + 30px);
            position: relative;
            left: -15px;
        }
    }
    li {
        // margin: 0 7px;
        padding: 0 7px;
        @media (max-width: 767px) {
            padding: 0 15px;
            padding-bottom: 8px;
        }
    }
    a {
        display: block;
        .img-bx {
            img {
                width: 100%;
            }
        }
        @media (max-width: 767px) {
            box-shadow: 0 0 10px rgba(#000000, 0.1);
        }
        @media (min-width: 768px) {
            &:hover {
                background-color: #fff;
                box-shadow: 0 0 10px rgba(#000000, 0.1);
                .sh-dotbtn {
                    .ins-bx {
                        background: $hover;
                    }
                    span {
                        color: #fff;
                    }
                    span:before {
                        right: 0;
                        width: 140px;
                        background-color: #fff;
                    }
                    span:after {
                        right: 0;
                        background-color: #fff;
                    }
                }
            }
        }
    }
    .des-bx {
        padding: 30px 40px 35px 40px;
        @media (max-width: 767px) {
            padding: 20px 20px 30px 20px;
        }
        .t {
            @include fontset(20, 1.5, 1, 400, #000);
            margin-bottom: 13px;
            @media (max-width: 767px) {
                margin-bottom: 8px;
                @include fontset(26, 1.5, 1, 400, #000);
            }
        }
        .sh-des {
            margin-bottom: 33px;
            @media (max-width: 767px) {
                margin-bottom: 25px;
            }
        }
    }
    .slick-prev {
        width: 64px;
        height: 64px;
        background-color: $main_color;
        transition: 0.4s ease;
        left: -137px;
        @media (max-width: 1480px) {
            left: -25px;
        }
        @media (max-width: 767px) {
            width: 40px;
            height: 40px;
            top: calc(50% - 123px);
            left: -10px;
        }
        &::before,
        &::after {
            position: absolute;
            content: "";
            width: 1px;
            height: 20px;
            background-color: #fff;
            left: 50%;
            transition: 0.4s ease;
            @media (max-width: 767px) {
                height: 16px;
            }
        }
        &::before {
            top: 17px;
            transform: translateX(-50%) rotate(45deg);
            @media (max-width: 767px) {
                top: 8px;
            }
        }
        &::after {
            top: 31px;
            transform: translateX(-50%) rotate(-45deg);
            @media (max-width: 767px) {
                top: 19px;
            }
        }
        @media (min-width: 768px) {
            &:hover {
                background-color: #fff;
                &::before,
                &::after {
                    background-color: #cccccc;
                }
            }
        }
    }
    .slick-next {
        width: 64px;
        height: 64px;
        background-color: $main_color;
        transition: 0.4s ease;
        right: -137px;
        @media (max-width: 1480px) {
            right: -25px;
        }
        @media (max-width: 767px) {
            width: 40px;
            height: 40px;
            top: calc(50% - 123px);
            right: -10px;
        }
        &::before,
        &::after {
            position: absolute;
            content: "";
            width: 1px;
            height: 20px;
            background-color: #fff;
            left: 50%;
            transition: 0.4s ease;
            @media (max-width: 767px) {
                height: 16px;
            }
        }
        &::before {
            top: 17px;
            transform: translateX(-50%) rotate(-45deg);
            @media (max-width: 767px) {
                top: 8px;
            }
        }
        &::after {
            top: 31px;
            transform: translateX(-50%) rotate(45deg);
            @media (max-width: 767px) {
                top: 19px;
            }
        }
        @media (min-width: 768px) {
            &:hover {
                background-color: #fff;
                &::before,
                &::after {
                    background-color: #cccccc;
                }
            }
        }
    }
}
.sh-banner {
    position: relative;
    z-index: 3;
    .r-bx {
        width: 1368px;
        margin-left: auto;
        @media (max-width: 1600px) {
            width: 71.25vw;
        }
        @media (max-width: 767px) {
            width: 100%;
        }
        .bgcover {
            background-size: cover !important;
            padding-bottom: 360px;
            @media (max-width: 767px) {
                padding-bottom: 50.1%;
            }
        }
    }
    .l-card {
        position: absolute;
        right: 1158px;
        top: 50%;
        transform: translateY(-50%);
        width: 745px;
        height: 240px;
        background-color: $main_color;
        padding-left: 345px;
        @media (max-width: 1600px) {
            right: auto;
            left: -300px;
        }
        @media (max-width: 767px) {
            left: 0;
            width: 160px;
            height: 102px;
            padding-left: 20px;
            padding-right: 10px;
            bottom: -20px;
            top: auto;
            transform: none;
        }
        .cen-bx {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            @media (max-width: 767px) {
                top: 44%;
            }
        }
        .e-ti {
            @include fontset(48, 1.2, 0, 300, #fff);
            @media (max-width: 767px) {
                font-size: pxToEm(30);
                font-weight: 700;
            }
        }
        .t {
            @include fontset(24, 1.2, 2, 300, #fff);
            @media (max-width: 767px) {
                font-size: pxToEm(18);
            }
            span {
                position: relative;
                padding-top: 16px;
                margin-top: 16px;
                @media (max-width: 767px) {
                    padding-top: 8px;
                    margin-top: 6px;
                }
                &::before {
                    position: absolute;
                    content: "";
                    top: 0;
                    left: 0;
                    width: 40px;
                    height: 1px;
                    background-color: #fff;
                }
            }
        }
    }
}

/// sunny新增

.serlist .tag a,
.key-bx .tag {
    display: inline-block;
    color: #82d1cb;
}
.serlist .tag a {
    @media (max-width: 767px) {
        box-shadow: none;
    }
}
.serlist li {
    cursor: pointer;
}
.serlist a:hover {
    box-shadow: none;
}
.key-bx {
    margin-top: 48px;
}

body {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
}
