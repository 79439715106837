@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Noto+Sans+TC:wght@300;400;500;700&display=swap);@charset "UTF-8";
/******************************************/
/*		bootstrap-okadmin
/******************************************/
/* > reset */
/******************************************/
/*		slick
/******************************************/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url(../assets/slick.eot?ced611da);
  src: url(../assets/slick.eot?ced611da?#iefix) format("embedded-opentype"), url(../assets/slick.woff?b7c9e1e4) format("woff"), url(../assets/slick.ttf?d41f55a7) format("truetype"), url(../assets/slick.svg?f97e3bbf#slick) format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

/******************************************/
/*		mCustomScrollbar
/******************************************/
/******************************************/
/*		fullpage
/******************************************/
@CHARSET "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre,
form, fieldset, input, textarea, p, blockquote {
  padding: 0;
  margin: 0; }

a {
  text-decoration: none; }

fieldset, img {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-weight: normal;
  font-style: normal; }

strong {
  font-weight: bold; }

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
  color: #444; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: 0; }

/* Custom CSS
 * --------------------------------------- */
body {
  font-family: arial,helvetica;
  color: #333;
  color: rgba(0, 0, 0, 0.5); }

.wrap {
  margin-left: auto;
  margin-right: auto;
  width: 960px;
  position: relative; }

h1 {
  font-size: 6em; }

p {
  font-size: 2em; }

.intro p {
  width: 50%;
  margin: 0 auto;
  font-size: 1.5em; }

.section {
  text-align: center; }

#menu li {
  display: inline-block;
  margin: 10px;
  color: #000;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 10px;
  border-radius: 10px; }

#menu li.active {
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  color: #fff; }

#menu li a {
  text-decoration: none;
  color: #000; }

#menu li.active a:hover {
  color: #000; }

#menu li:hover {
  background: rgba(255, 255, 255, 0.8); }

#menu li a,
#menu li.active a {
  padding: 9px 18px;
  display: block; }

#menu li.active a {
  color: #fff; }

#menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  z-index: 70;
  width: 100%;
  padding: 0;
  margin: 0; }

.twitter-share-button {
  position: fixed;
  z-index: 99;
  right: 149px;
  top: 9px; }

#download {
  margin: 10px 0 0 0;
  padding: 15px 10px;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -ms-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: linear-gradient(top, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5bc0de', endColorstr='#2f96b4', GradientType=0);
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); }

#download a {
  text-decoration: none;
  color: #fff; }

#download:hover {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #2F96B4;
  background-position: 0 -15px;
  -webkit-transition: background-position .1s linear;
  -moz-transition: background-position .1s linear;
  -ms-transition: background-position .1s linear;
  -o-transition: background-position .1s linear;
  transition: background-position .1s linear; }

#infoMenu {
  height: 20px;
  color: #f2f2f2;
  position: fixed;
  z-index: 70;
  bottom: 0;
  width: 100%;
  text-align: right;
  font-size: 0.9em;
  padding: 8px 0 8px 0; }

#infoMenu ul {
  padding: 0 40px; }

#infoMenu li a {
  display: block;
  margin: 0 22px 0 0;
  color: #333; }

#infoMenu li a:hover {
  text-decoration: underline; }

#infoMenu li {
  display: inline-block;
  position: relative; }

#examplesList {
  display: none;
  background: #282828;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 20px;
  float: left;
  position: absolute;
  bottom: 29px;
  right: 0;
  width: 638px;
  text-align: left; }

#examplesList ul {
  padding: 0; }

#examplesList ul li {
  display: block;
  margin: 5px 0; }

#examplesList ul li a {
  color: #BDBDBD;
  margin: 0; }

#examplesList ul li a:hover {
  color: #f2f2f2; }

#examplesList .column {
  float: left;
  margin: 0 20px 0 0; }

#examplesList h3 {
  color: #f2f2f2;
  font-size: 1.2em;
  margin: 0 0 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  padding: 0 0 5px 0; }

/* Demos Menu
 * --------------------------------------- */
#demosMenu {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999; }

/******************************************/
/*		base 歸零 變數
/******************************************/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font: inherit; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/******************************************/
/*		layout 共用
/******************************************/
/*----------------------通用設定------------------------*/
p::selection,
h1::selection,
h2::selection,
h3::selection,
h4::selection,
b::selection,
strong::selection,
span::selection,
li::selection,
div::selection,
a::selection,
img::selection,
tr::selection,
td::selection,
th::selection {
  color: #ffffff;
  background: #2f3a88; }

body {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
  line-height: 1em;
  overflow: auto;
  font-size: 16px;
  color: #000000;
  word-break: break-word;
  letter-spacing: 0.1em;
  z-index: 0;
  background: #ffffff;
  -webkit-text-size-adjust: 100%;
  opacity: 0;
  transition: 1.2s;
  font-family: "Lato" "Noto Sans TC"; }

body.loading {
  opacity: 1;
  transition: opacity 1.2s; }

a {
  text-decoration: none;
  outline: 0; }

a,
a:hover {
  transition: 0.4s; }

img {
  vertical-align: middle;
  max-width: 100%; }

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important; }

a[href^="tel"] {
  color: inherit;
  /* Inherit text color of parent element. */
  text-decoration: none;
  /* Remove underline. */
  /* Additional css `propery: value;` pairs here… */ }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.jqimgFill {
  position: relative;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: inline-block;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out; }

.jqimgFill img,
.jqimgFill-tc img {
  left: 0;
  top: 0;
  margin: 0;
  display: block;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-height: none;
  max-width: none;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out; }

input,
select,
textarea {
  width: 100%;
  height: 56px;
  outline: none;
  padding: 0;
  color: #000;
  border: 1px solid #ffffff;
  font-size: 1rem;
  border-radius: 5px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important; }
  @media screen and (max-width: 640px) {
    input,
    select,
    textarea {
      font-size: 1.25rem; } }
  input:focus,
  select:focus,
  textarea:focus {
    transition: 0.4s;
    border: 1px solid #eee; }

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background: #000 !important; }

textarea {
  height: 218px;
  padding: 10px; }

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #666;
  line-height: normal; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #666;
  line-height: 43px; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #666;
  line-height: 43px; }

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #666;
  line-height: normal; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

input[type="date"],
input[type="time"] {
  -webkit-appearance: none; }

select {
  font-family: "Noto Sans TC";
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-size: 3.4%;
  padding-right: 14px;
  height: 40px;
  width: 100%; }

select::-ms-expand {
  display: none; }

.selectBox {
  background: #fff; }

body :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }
  @media screen and (max-width: 960px) {
    body :target:before {
      height: 100px;
      margin: -100px 0 0; } }

body .slide-down :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }

.slick-slide {
  outline: 0; }

/*----------------------大區塊------------------------*/
#Wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%; }

main[role="main"] {
  display: block;
  width: 100%;
  position: relative;
  margin-top: 80px;
  overflow: hidden; }
  @media (max-width: 991px) {
    main[role="main"] {
      margin-top: 60px; } }

section {
  position: relative; }

article {
  width: 1200px;
  max-width: 85%;
  margin: 0 auto;
  position: relative;
  z-index: 1; }

/*----------------------每個選單Highlight------------------------*/
/*----------------------內頁單元標題------------------------*/
/*----------------------tag_list------------------------*/
/*----------------------編輯器------------------------*/
.editor_Content {
  font-size: 1rem;
  line-height: 1.5; }
  @media (max-width: 767px) {
    .editor_Content {
      font-size: 1.25rem; } }
  .editor_Content ol {
    list-style-type: decimal;
    padding-left: 10px; }
  .editor_Content ul {
    list-style-type: initial;
    padding-left: 20px; }
  .editor_Content img {
    max-width: 100%;
    height: auto !important; }
  .editor_Content b,
  .editor_Content strong {
    font-weight: bold;
    font-size: inherit; }
  .editor_Content em {
    font-style: italic; }
  .editor_Content a {
    text-decoration: underline;
    color: #0782c1; }
  .editor_Content iframe {
    max-width: 100%; }
  .editor_Content h1,
  .editor_Content h2,
  .editor_Content h3,
  .editor_Content h4,
  .editor_Content h5,
  .editor_Content h6 {
    margin: inherit; }
  .editor_Content blockquote {
    font-style: italic;
    padding: 2px 20px 0 8px;
    margin-left: 40px;
    border-left: 5px solid #ccc; }
  .editor_Content td,
  .editor_Content th,
  .editor_Content tr {
    margin: initial; }
  .editor_Content table {
    border-collapse: inherit; }

.editor_Box {
  margin: auto;
  line-height: 1.5;
  font-size: 0.9375rem;
  color: #000000; }
  .editor_Box ul,
  .editor_Box ol {
    padding-left: 40px; }
  .editor_Box img {
    max-width: 100%;
    height: auto !important; }
  .editor_Box iframe {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .editor_Box iframe {
        height: 100% !important; } }
  .editor_Box h1,
  .editor_Box h2,
  .editor_Box h3,
  .editor_Box h4,
  .editor_Box h5,
  .editor_Box h6 {
    margin: inherit; }

.mo_use {
  display: none; }

/*----------------------back------------------------*/
.back_btn,
.more_btn {
  width: 100%;
  margin-top: 70px;
  font-size: 1.125rem; }
  .back_btn a,
  .more_btn a {
    margin: auto;
    display: inline-block;
    color: #ffffff;
    text-decoration: none;
    padding: 19.5px 49px;
    background-color: #4759d9; }
    .back_btn a:hover,
    .more_btn a:hover {
      background: #4759d9;
      opacity: 0.5; }

/*----------------------上下則------------------------*/
.seesaw {
  position: relative;
  width: 100%;
  font-size: 1rem;
  margin-top: 61px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .seesaw a {
    color: #de4242;
    text-decoration: none; }
  .seesaw .left_button:before,
  .seesaw .right_button:before,
  .seesaw .back_button {
    transition: 0.4s; }
  .seesaw .left_button,
  .seesaw .right_button {
    position: absolute; }
    .seesaw .left_button:before,
    .seesaw .right_button:before {
      content: "";
      display: block;
      width: 70px;
      height: 2px;
      background: #de4242;
      margin-bottom: 2px; }
    .seesaw .left_button:hover:before,
    .seesaw .right_button:hover:before {
      width: 90px; }
  .seesaw .left_button {
    right: 0; }
  .seesaw .right_button {
    left: 0;
    text-align: right; }
  .seesaw .back_button {
    font-size: 1rem;
    width: 230px;
    height: auto;
    padding: 11px 0;
    border: 1px solid #de4242;
    text-align: center; }
    .seesaw .back_button:hover {
      background: #de4242; }
      .seesaw .back_button:hover a {
        color: #ffffff; }

/*----------------------GO TOP------------------------*/
.show-topbtn.top_btn {
  opacity: 1;
  pointer-events: all; }

.top_btn {
  cursor: pointer;
  transition: opacity 0.4s ease;
  opacity: 0;
  position: fixed;
  bottom: 20px;
  right: 58px;
  z-index: 8;
  z-index: 9;
  width: 64px;
  height: 64px;
  background-color: #cccccc;
  border-radius: 50%;
  pointer-events: none; }
  @media (max-width: 767px) {
    .top_btn {
      right: 20px;
      bottom: 75px; } }
  .top_btn .arr {
    width: 28px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  @media (min-width: 768px) {
    .top_btn:hover {
      background-color: #82D1CB; } }
  .top_btn.fix {
    position: absolute;
    transition: 0s;
    bottom: -32px; }
    @media screen and (max-width: 768px) {
      .top_btn.fix {
        bottom: -94px; } }

/*----------------------頁數------------------------*/
.page {
  font-size: 1rem;
  clear: both;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-top: 70px; }
  @media (max-width: 767px) {
    .page {
      margin-top: 50px;
      text-align: center; } }
  .page dt,
  .page dd {
    vertical-align: middle;
    display: inline-block;
    position: relative; }
    .page dt a,
    .page dd a {
      display: block;
      width: 56px;
      height: 56px;
      line-height: 56px;
      text-decoration: none;
      color: #707070;
      transition: 0.4s; }
      @media (max-width: 767px) {
        .page dt a,
        .page dd a {
          line-height: 35px;
          font-size: 0.875rem;
          width: 34px;
          height: 34px; } }
  .page dd {
    width: 56px;
    height: 56px;
    margin: 0 14px;
    position: relative;
    transition: 0.4s;
    border-radius: 5px;
    text-align: center; }
    @media (max-width: 767px) {
      .page dd {
        margin: 0px 4px;
        width: 34px;
        height: 34px; } }
    @media (max-width: 350px) {
      .page dd {
        margin: 0px; } }
    .page dd::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 50%;
      top: 50%;
      transition: 0.4s;
      opacity: 1;
      border-radius: 50%;
      border: 1px solid #707070;
      pointer-events: none;
      transform: translate(-50%, -50%);
      z-index: -1; }
      @media (max-width: 767px) {
        .page dd::before {
          left: 48%; } }
    .page dd span {
      position: relative; }
    @media (min-width: 767px) {
      .page dd:hover::before {
        border-color: #82D1CB; }
      .page dd:hover a {
        color: #82D1CB; } }
  .page dd.active::before {
    border-color: #82D1CB; }
  .page dd.active a {
    color: #82D1CB; }
  .page dt.rtbn,
  .page dt.ltbn {
    position: relative; }
    @media (min-width: 768px) {
      .page dt.rtbn:hover::before,
      .page dt.ltbn:hover::before {
        border-color: #82D1CB; }
      .page dt.rtbn:hover a::before, .page dt.rtbn:hover a::after,
      .page dt.ltbn:hover a::before,
      .page dt.ltbn:hover a::after {
        background-color: #82D1CB; } }
    .page dt.rtbn::before,
    .page dt.ltbn::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid #707070;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 0.4s ease; }
  .page dt.rtbn {
    margin-left: 14px; }
    @media (max-width: 767px) {
      .page dt.rtbn {
        margin-left: 4px; } }
    @media (max-width: 350px) {
      .page dt.rtbn {
        margin-left: 0; } }
  .page dt.ltbn {
    margin-right: 14px; }
    @media (max-width: 767px) {
      .page dt.ltbn {
        margin-right: 4px; } }
    @media (max-width: 350px) {
      .page dt.ltbn {
        margin-right: 0; } }
  .page dt.rtbn a,
  .page dt.ltbn a {
    width: 56px;
    height: 56px;
    transition: 0.4s; }
    @media (max-width: 767px) {
      .page dt.rtbn a,
      .page dt.ltbn a {
        width: 34px;
        height: 34px; } }
  .page dt.ltbn.nopage,
  .page dt.rtbn.nopage {
    pointer-events: none; }
  .page dt.ltbn a {
    position: relative; }
    @media (max-width: 767px) {
      .page dt.ltbn a {
        margin-right: 0; } }
    .page dt.ltbn a:before {
      background-color: #333;
      position: absolute;
      content: "";
      top: 20px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      width: 2px;
      height: 12px;
      transition: 0.4s; }
      @media (max-width: 767px) {
        .page dt.ltbn a:before {
          width: 1px;
          top: 8px; } }
    .page dt.ltbn a::after {
      background-color: #333;
      position: absolute;
      content: "";
      top: 28px;
      left: 50%;
      transform: translateX(-50%) rotate(-45deg);
      width: 2px;
      height: 12px;
      transition: 0.4s; }
      @media (max-width: 767px) {
        .page dt.ltbn a::after {
          width: 1px;
          top: 16px; } }
  .page dt.rtbn a {
    position: relative; }
    @media (max-width: 767px) {
      .page dt.rtbn a {
        margin-left: 0; } }
    .page dt.rtbn a:before {
      background-color: #333;
      position: absolute;
      content: "";
      top: 20px;
      left: 50%;
      transform: translateX(-50%) rotate(-45deg);
      width: 2px;
      height: 12px;
      transition: 0.4s; }
      @media (max-width: 767px) {
        .page dt.rtbn a:before {
          width: 1px;
          top: 8px; } }
    .page dt.rtbn a::after {
      background-color: #333;
      position: absolute;
      content: "";
      top: 28px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      width: 2px;
      height: 12px;
      transition: 0.4s; }
      @media (max-width: 767px) {
        .page dt.rtbn a::after {
          width: 1px;
          top: 16px; } }

@media screen and (max-width: 768px) {
  .mo_use {
    display: block; }
  .pc_use {
    display: none; }
  right: 20px; }

@media (max-width: 767px) {
  .back_btn,
  .more_btn {
    font-size: 1.0625rem; }
    .back_btn a,
    .more_btn a {
      padding: 12.5px 25px;
      border-radius: 4px; } }

/*----------------------header------------------------*/
.header_show {
  transition: 0.4s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 100;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); }
  .header_show li {
    list-style: none; }
  .header_show.active {
    background: #131314; }

.header_box {
  position: relative;
  width: 100%;
  height: 80px;
  padding: 0 180px;
  padding-right: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem; }
  @media (max-width: 1600px) {
    .header_box {
      padding: 0 100px;
      padding-right: 200px; } }
  @media (max-width: 1500px) {
    .header_box {
      padding: 0 50px;
      padding-right: 200px; } }
  @media (max-width: 1200px) {
    .header_box {
      padding: 0 20px;
      padding-right: 200px; } }

.logo {
  width: 400px; }
  .logo a {
    display: block; }
  .logo .mobile {
    display: none; }
  @media (max-width: 1366px) {
    .logo {
      width: 280px; } }
  @media (max-width: 1200px) {
    .logo {
      width: 190px; }
      .logo .desk {
        display: none; }
      .logo .mobile {
        display: block; } }

/*----------------------nav------------------------*/
.nav_box {
  position: relative;
  display: flex;
  height: 100%; }
  .nav_box ul {
    display: flex;
    height: 100%; }
    .nav_box ul li {
      position: relative;
      text-align: center;
      margin-right: 40px; }
      @media (max-width: 1440px) {
        .nav_box ul li {
          margin-right: 15px; } }
  .nav_box li > a,
  .nav_box .lan-bx > a {
    width: 100%;
    height: 100%;
    display: block;
    color: #707070;
    font-size: 1rem;
    position: relative; }
    @media (min-width: 768px) {
      .nav_box li > a:hover,
      .nav_box .lan-bx > a:hover {
        color: #FCAD28; }
        .nav_box li > a:hover::before,
        .nav_box .lan-bx > a:hover::before {
          opacity: 1; } }
    .nav_box li > a span,
    .nav_box .lan-bx > a span {
      position: relative;
      top: 50%;
      transform: translateY(-50%); }
    .nav_box li > a::before,
    .nav_box .lan-bx > a::before {
      transition: 0.4s;
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background: #FCAD28;
      bottom: 0px;
      left: 0px;
      opacity: 0; }
    .nav_box li > a:hover:after,
    .nav_box .lan-bx > a:hover:after {
      width: 77%;
      opacity: 1; }
    .nav_box li > a:hover .en,
    .nav_box .lan-bx > a:hover .en {
      opacity: 0; }
    .nav_box li > a:hover .tw,
    .nav_box .lan-bx > a:hover .tw {
      opacity: 1; }
  .nav_box .lan-bx {
    display: flex; }
    .nav_box .lan-bx a.active {
      color: #fff;
      background-color: #82D1CB; }
    .nav_box .lan-bx a {
      width: 80px;
      display: block;
      text-align: center;
      font-size: 0.875rem;
      color: #82D1CB;
      transition: 0.4s ease; }
      .nav_box .lan-bx a span {
        display: block; }
      .nav_box .lan-bx a::before {
        display: none; }
      @media (min-width: 768px) {
        .nav_box .lan-bx a:hover {
          color: #fff;
          background-color: #82D1CB; } }

/*----------------------nav icon------------------------*/
.menu-wrapper {
  display: none; }

.menu-wrapper {
  display: none;
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  margin: auto;
  width: 24px;
  height: 22px;
  cursor: pointer;
  z-index: 10; }

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: 24px;
  height: 2px; }

.hamburger-menu {
  position: relative;
  transform: translateY(10px);
  background: #707070;
  transition: all 0ms 300ms; }
  .hamburger-menu.animate {
    background: rgba(255, 255, 255, 0); }

.hamburger-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  background: #707070;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.hamburger-menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  background: #707070;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.hamburger-menu.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.hamburger-menu.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.menu-icon {
  display: none;
  top: 45%;
  right: -0.7em;
  z-index: 100;
  width: 45px;
  height: 39px;
  margin-top: 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  position: absolute; }
  @media (max-width: 768px) {
    .menu-icon {
      display: block; } }

.menu-icon-block {
  display: none;
  position: relative;
  height: 100%;
  margin: auto; }
  .menu-icon-block i {
    position: absolute;
    display: block;
    margin: auto;
    width: 72%;
    height: 2px;
    background-color: #ffffff;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em; }
    .menu-icon-block i.i1 {
      top: 0%; }
    .menu-icon-block i.i2 {
      top: 25%; }
    .menu-icon-block i.i3 {
      top: 51%; }

@media (max-width: 991px) {
  .logo {
    width: 190px; }
  .header_show {
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); }
  .header_box {
    width: 100%;
    height: 60px;
    font-size: 1.0625rem;
    margin: 0 auto;
    padding: 0;
    padding: 0 20px; }
  .nav_box nav {
    width: 100%;
    margin: auto;
    top: calc(50% - 37px);
    transform: translateY(-50%);
    position: relative; }
  .nav_box {
    display: none;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
    z-index: 11;
    background: url(../images/btn_dot.jpg?7c04b7c5) no-repeat center/cover;
    background-color: #fff; }
    .nav_box ul {
      display: block; }
      .nav_box ul li {
        width: 100%; }
        .nav_box ul li a {
          font-size: 1.625rem;
          padding: 23px 15px;
          text-align: center;
          text-decoration: none;
          position: relative;
          font-weight: 300;
          color: black; }
          .nav_box ul li a::before {
            position: absolute;
            content: "";
            bottom: -14px;
            display: none; }
          .nav_box ul li a span {
            top: auto;
            transform: none; }
    .nav_box ul > li:not(:last-child) {
      margin-right: 0; }
    .nav_box .lan-bx {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%; }
      .nav_box .lan-bx a {
        width: 33.33333%;
        height: 75px;
        background-color: #82D1CB;
        color: #fff; }
        .nav_box .lan-bx a:not(:last-child) {
          border-right: 1px solid #fff; }
  .menu-wrapper {
    display: block; } }

.search {
  position: absolute;
  right: -185px;
  top: 22px; }
  .search .box {
    width: 175px;
    border-radius: 10px;
    border: 1px solid #82d1cb;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 1250px) {
      .search .box {
        width: 160px; } }
    @media (max-width: 991px) {
      .search .box {
        width: 88%;
        margin: 0 auto; } }
  .search input {
    flex-grow: 1;
    border-radius: 10px;
    height: 40px;
    letter-spacing: 0.05em;
    padding: 0 10px;
    font-family: "Noto Sans TC"; }
    .search input::placeholder {
      font-family: "Noto Sans TC";
      font-weight: 300;
      letter-spacing: 0.05em;
      color: #707070; }
    .search input:focus {
      border: none; }
  .search .line {
    width: 1px;
    height: 20px;
    background-color: #82d1cb; }
  .search a {
    width: 50px;
    padding: 0 8px; }

@media (max-width: 991px) {
  .search {
    width: 100%;
    padding-top: 25px;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
    z-index: 50; }
    .search input {
      font-size: 1.625rem; } }

footer {
  padding: 0 180px;
  padding-top: 40px;
  padding-bottom: 52px;
  background-color: #fff;
  position: relative;
  z-index: 4; }
  @media (max-width: 1500px) {
    footer {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (max-width: 1200px) {
    footer {
      padding-left: 20px;
      padding-right: 20px; } }
  @media (max-width: 767px) {
    footer {
      padding-bottom: 68px;
      padding-top: 117px; } }
  footer .t-bx {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 991px) {
      footer .t-bx {
        display: block; } }
    footer .t-bx .f-logo {
      width: 178px; }
      @media (max-width: 991px) {
        footer .t-bx .f-logo {
          width: 190px;
          margin: 0 auto; } }
    footer .t-bx .link-bx {
      display: flex; }
      @media (max-width: 991px) {
        footer .t-bx .link-bx {
          justify-content: center;
          margin-top: 35px; } }
      footer .t-bx .link-bx a {
        width: 40px;
        display: block;
        height: 40px;
        position: relative; }
        @media (max-width: 991px) {
          footer .t-bx .link-bx a {
            width: 45px;
            height: 45px; } }
        footer .t-bx .link-bx a:not(:last-child) {
          margin-right: 15px; }
          @media (max-width: 991px) {
            footer .t-bx .link-bx a:not(:last-child) {
              margin-right: 44px; } }
        footer .t-bx .link-bx a img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          transition: 0.4s ease; }
        footer .t-bx .link-bx a .unhov {
          opacity: 1; }
        footer .t-bx .link-bx a .hov {
          opacity: 0; }
        @media (min-width: 768px) {
          footer .t-bx .link-bx a:hover .unhov {
            opacity: 0; }
          footer .t-bx .link-bx a:hover .hov {
            opacity: 1; } }
  footer .b-bx {
    display: flex;
    justify-content: space-between;
    margin-top: 23px; }
    @media (max-width: 991px) {
      footer .b-bx {
        display: block;
        margin-top: 30px; } }
    @media (max-width: 991px) {
      footer .b-bx .l ul {
        max-width: 292px;
        width: 100%;
        margin: 0 auto; } }
    @media (max-width: 450px) {
      footer .b-bx .l ul {
        max-width: 100%; } }
    footer .b-bx .l ul li a {
      font-size: 1rem;
      line-height: 1.8;
      letter-spacing: 0px;
      font-weight: 300;
      color: #444444;
      display: flex; }
      @media (max-width: 767px) {
        footer .b-bx .l ul li a {
          font-size: 1.25rem;
          line-height: 1.5;
          letter-spacing: 0px;
          font-weight: 300;
          color: #444444; } }
      footer .b-bx .l ul li a i {
        min-width: 55px;
        display: block; }
        @media (max-width: 767px) {
          footer .b-bx .l ul li a i {
            min-width: 60px; } }
    @media (max-width: 991px) {
      footer .b-bx .r .menu-bx {
        display: none; } }
    footer .b-bx .r .menu-bx ul {
      display: flex; }
    footer .b-bx .r .menu-bx li:not(:last-child) {
      margin-right: 40px; }
      @media (max-width: 991px) {
        footer .b-bx .r .menu-bx li:not(:last-child) {
          margin-right: 15px; } }
    footer .b-bx .r .menu-bx a {
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0px;
      font-weight: 300;
      color: #707070; }
    footer .b-bx .r .copyright {
      margin-top: 35px;
      font-size: 0.875rem;
      line-height: 2;
      letter-spacing: 0px;
      font-weight: 400;
      color: #cccccc;
      text-align: right; }
      @media (max-width: 991px) {
        footer .b-bx .r .copyright {
          text-align: center;
          margin-top: 25px; } }
      @media (max-width: 767px) {
        footer .b-bx .r .copyright {
          font-size: 0.75rem; } }
      footer .b-bx .r .copyright a {
        font-size: 0.875rem;
        line-height: 2;
        letter-spacing: 0px;
        font-weight: 400;
        color: #cccccc;
        position: relative;
        padding-left: 9px;
        margin-left: 7px; }
        @media (max-width: 767px) {
          footer .b-bx .r .copyright a {
            font-size: 0.75rem; } }
        footer .b-bx .r .copyright a::before {
          position: absolute;
          content: "";
          left: 0;
          top: 3px;
          width: 1px;
          height: 12px;
          background-color: #ccc; }

.r-fixbtn.show {
  opacity: 1;
  pointer-events: all; }

.r-fixbtn {
  position: fixed;
  right: 16px;
  top: 40%;
  transform: translateY(-50%);
  width: 150px;
  height: 150px;
  padding: 15px;
  border-radius: 50%;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACJJREFUeNpiYCAWfFko7gDE+3GxmaDqLgBxIQH2MLUOIMAAvHwyXyFSB54AAAAASUVORK5CYII=);
  z-index: 80;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease; }
  @media (max-width: 767px) {
    .r-fixbtn {
      bottom: 0;
      right: 0;
      width: 100%;
      height: 54px;
      background: none;
      border-radius: 0;
      bottom: 0;
      top: auto;
      transform: none;
      padding: 0;
      transition: 0s;
      box-shadow: 0 0 12px rgba(112, 112, 112, 0.31); } }
  .r-fixbtn .tx-bx {
    background-color: #FCAD28;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    text-align: center; }
    @media (max-width: 767px) {
      .r-fixbtn .tx-bx {
        border-radius: 0; } }
  .r-fixbtn .center-bx {
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
    @media (max-width: 767px) {
      .r-fixbtn .center-bx {
        top: auto;
        transform: none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%; } }
  .r-fixbtn .img-bx {
    margin-bottom: 5px; }
    @media (max-width: 767px) {
      .r-fixbtn .img-bx {
        width: 25px;
        margin-bottom: 0;
        margin-right: 14px; } }
  .r-fixbtn .tx {
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: 1px;
    font-weight: 300;
    color: #fff; }
    @media (max-width: 767px) {
      .r-fixbtn .tx {
        font-weight: 700; } }
  @media (min-width: 768px) {
    .r-fixbtn:hover {
      opacity: 0.8; } }

.lang_tw .tw {
  display: block; }

.lang_tw .en {
  display: none; }

.lang_tw .jp {
  display: none; }

.lang_en .tw {
  display: none; }

.lang_en .en {
  display: block; }

.lang_en .jp {
  display: none; }

.lang_jp .tw {
  display: none; }

.lang_jp .en {
  display: none; }

.lang_jp .jp {
  display: block; }

/******************************************/
/*		pages
/******************************************/
.indexPage .ind-ti {
  text-align: center; }
  .indexPage .ind-ti .ti {
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: 4px;
    font-weight: 400;
    color: #FCAD28;
    margin-bottom: 0px; }
    @media (max-width: 991px) {
      .indexPage .ind-ti .ti {
        font-size: 1.25rem;
        margin-bottom: 18px; } }
  .indexPage .ind-ti .e-ti {
    font-size: 7.75rem;
    line-height: 1.1;
    letter-spacing: 0px;
    font-weight: 300;
    color: #979797;
    margin-top: -5px;
    position: relative;
    padding-bottom: 85px; }
    @media (max-width: 991px) {
      .indexPage .ind-ti .e-ti {
        font-size: 2.5rem;
        font-weight: 700;
        padding-bottom: 45px; } }
    .indexPage .ind-ti .e-ti::before {
      position: absolute;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      bottom: 0px;
      width: 1px;
      height: 80px;
      background-color: #FCAD28; }
      @media (max-width: 991px) {
        .indexPage .ind-ti .e-ti::before {
          height: 40px; } }

.indexPage .banner-bx {
  position: relative;
  z-index: 3;
  position: relative;
  padding-bottom: 37.5%; }
  @media (max-width: 767px) {
    .indexPage .banner-bx {
      padding-bottom: 93.75%; } }
  .indexPage .banner-bx a {
    display: block; }
  .indexPage .banner-bx li.chbanner .bgcover {
    opacity: 1; }
  .indexPage .banner-bx .bgcover {
    padding-bottom: 37.5%;
    background-size: cover !important;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.4s ease; }
    @media (max-width: 767px) {
      .indexPage .banner-bx .bgcover {
        padding-bottom: 93.75%; } }
  .indexPage .banner-bx .show-box {
    position: absolute;
    bottom: 19px;
    right: 166px;
    z-index: 10;
    display: flex;
    width: 159px;
    justify-content: space-between; }
    @media (max-width: 1200px) {
      .indexPage .banner-bx .show-box {
        right: 80px;
        bottom: 10px; } }
    @media (max-width: 767px) {
      .indexPage .banner-bx .show-box {
        bottom: 3px;
        right: 10px;
        width: 125px; } }

@keyframes lineani {
  0% {
    width: 0; }
  100% {
    width: 100%; } }
    .indexPage .banner-bx .show-box .ani.line::before {
      animation: lineani 5s linear forwards; }
    .indexPage .banner-bx .show-box .line {
      width: 80px;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.5);
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      @media (max-width: 768px) {
        .indexPage .banner-bx .show-box .line {
          width: 57px; } }
      .indexPage .banner-bx .show-box .line::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 1px;
        background-color: #fff; }
    .indexPage .banner-bx .show-box .indxnum,
    .indexPage .banner-bx .show-box .nexindx {
      position: relative;
      width: 40px;
      height: 40px; }
    .indexPage .banner-bx .show-box a {
      font-size: 0.875rem;
      line-height: 3;
      letter-spacing: 1.8px;
      font-weight: 400;
      color: #ffffff;
      transition: 0.4s;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 40px;
      height: 40px;
      text-align: center;
      opacity: 0;
      pointer-events: none; }
      @media (max-width: 767px) {
        .indexPage .banner-bx .show-box a {
          font-size: 0.75rem;
          letter-spacing: 0;
          line-height: 3.3; } }
    .indexPage .banner-bx .show-box a.show-me {
      opacity: 1;
      pointer-events: auto; }
    @media (max-width: 767px) {
      .indexPage .banner-bx .show-box .indxnum a {
        margin-right: 3px; } }
    .indexPage .banner-bx .show-box .nexindx a {
      color: rgba(255, 255, 255, 0.5); }
      @media (min-width: 768px) {
        .indexPage .banner-bx .show-box .nexindx a:hover {
          color: #ffffff; } }
      @media (max-width: 767px) {
        .indexPage .banner-bx .show-box .nexindx a {
          margin-left: 3px; } }

.indexPage .item1 {
  padding-top: 120px; }
  @media (max-width: 767px) {
    .indexPage .item1 {
      padding-top: 45px; } }
  .indexPage .item1 ul {
    margin-top: 70px; }
    @media (max-width: 991px) {
      .indexPage .item1 ul {
        margin-top: 50px; } }
    @media (max-width: 400px) {
      .indexPage .item1 ul .sh-dotbtn span:before {
        right: 15px; } }
    @media (max-width: 350px) {
      .indexPage .item1 ul .sh-dotbtn span:before {
        right: 0px;
        width: calc(43.5% + 15px); } }
    @media (max-width: 400px) {
      .indexPage .item1 ul .sh-dotbtn span::after {
        right: 15px; } }
    @media (max-width: 350px) {
      .indexPage .item1 ul .sh-dotbtn span::after {
        right: 0px; } }

.indexPage .item2 {
  padding-top: 120px; }
  @media (min-width: 1200px) {
    .indexPage .item2 .it2-bx {
      max-width: 1592px; } }
  .indexPage .item2 ul {
    margin-top: 62px; }
    .indexPage .item2 ul li {
      display: flex;
      align-items: center;
      position: relative;
      padding: 80px 0; }
      .indexPage .item2 ul li:not(:last-child) {
        margin-bottom: 30px; }
        @media (max-width: 767px) {
          .indexPage .item2 ul li:not(:last-child) {
            margin-bottom: 54px; } }
      @media (max-width: 767px) {
        .indexPage .item2 ul li {
          display: block;
          padding-top: 0;
          padding-bottom: 50px; } }
      .indexPage .item2 ul li .bg {
        width: 81%;
        height: 100%;
        background-color: #82D1CB;
        z-index: -1; }
        @media (max-width: 767px) {
          .indexPage .item2 ul li .bg {
            width: 100vw;
            right: auto;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 82%; } }
      .indexPage .item2 ul li .img-bx {
        width: 771px; }
        @media (max-width: 767px) {
          .indexPage .item2 ul li .img-bx {
            width: 100%; } }
      .indexPage .item2 ul li .des-bx {
        max-width: 390px;
        width: 100%; }
        @media (max-width: 767px) {
          .indexPage .item2 ul li .des-bx {
            max-width: 100%;
            margin-top: 30px; } }
        .indexPage .item2 ul li .des-bx .ti {
          font-size: 3rem;
          line-height: 1.4;
          letter-spacing: 0px;
          font-weight: 300;
          color: #fff; }
          @media (max-width: 767px) {
            .indexPage .item2 ul li .des-bx .ti {
              margin-bottom: -5px; } }
        .indexPage .item2 ul li .des-bx .ti-des {
          font-size: 1.5rem;
          line-height: 1.4;
          letter-spacing: 2px;
          font-weight: 300;
          color: #fff;
          position: relative;
          padding-bottom: 20px;
          margin-bottom: 20px; }
          .indexPage .item2 ul li .des-bx .ti-des::before {
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            width: 48px;
            height: 1px;
            background-color: #fff; }
        .indexPage .item2 ul li .des-bx .sh-des {
          color: #fff;
          max-height: 7.5em;
          overflow: hidden; }
          @media (max-width: 767px) {
            .indexPage .item2 ul li .des-bx .sh-des {
              max-height: 100%; } }
      .indexPage .item2 ul li .bgcover {
        background-size: cover !important;
        padding-bottom: 62.5%; }
    .indexPage .item2 ul li:nth-child(odd) {
      justify-content: flex-start; }
      .indexPage .item2 ul li:nth-child(odd) .bg {
        position: absolute;
        right: -171px; }
        .indexPage .item2 ul li:nth-child(odd) .bg::before {
          position: absolute;
          content: "";
          left: 842px;
          bottom: 0;
          width: 426px;
          height: 100%;
          background: url(../images/point.png?529156e2) no-repeat center center/contain; }
          @media (max-width: 767px) {
            .indexPage .item2 ul li:nth-child(odd) .bg::before {
              left: auto;
              right: -35px;
              top: -25px;
              width: 120px;
              height: 220px; } }
      .indexPage .item2 ul li:nth-child(odd) .img-bx {
        margin-right: 7.6%; }
        @media (max-width: 767px) {
          .indexPage .item2 ul li:nth-child(odd) .img-bx {
            margin-right: 0; } }
    .indexPage .item2 ul li:nth-child(even) {
      justify-content: flex-end; }
      .indexPage .item2 ul li:nth-child(even) .bg {
        position: absolute;
        left: -171px; }
        @media (max-width: 767px) {
          .indexPage .item2 ul li:nth-child(even) .bg {
            left: 50%; } }
        .indexPage .item2 ul li:nth-child(even) .bg::before {
          position: absolute;
          content: "";
          right: 842px;
          bottom: 0;
          width: 426px;
          height: 100%;
          background: url(../images/point_1.png?c549c0c0) no-repeat center center/contain; }
          @media (max-width: 767px) {
            .indexPage .item2 ul li:nth-child(even) .bg::before {
              background: url(../images/point.png?529156e2) no-repeat center center/contain;
              left: auto;
              right: -35px;
              top: -25px;
              width: 120px;
              height: 220px; } }
      .indexPage .item2 ul li:nth-child(even) .img-bx {
        margin-left: 7.6%;
        order: 2; }
        @media (max-width: 767px) {
          .indexPage .item2 ul li:nth-child(even) .img-bx {
            margin-left: 0; } }

.indexPage .item3 {
  padding-top: 130px; }
  .indexPage .item3 .ind-ti {
    text-align: left; }
    .indexPage .item3 .ind-ti .e-ti {
      padding-bottom: 0px; }
      .indexPage .item3 .ind-ti .e-ti::before {
        display: none; }
    .indexPage .item3 .ind-ti .sh-des {
      padding-left: 176px;
      position: relative; }
      .indexPage .item3 .ind-ti .sh-des::before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 160px;
        height: 1px;
        background-color: #FCAD28; }
  .indexPage .item3 ul.showall li {
    display: block !important; }
  .indexPage .item3 ul {
    margin-top: 70px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
    .indexPage .item3 ul li {
      width: calc((100% - 32px) / 3);
      position: relative;
      padding: 20px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACJJREFUeNpiYCAWfFko7gDE+3GxmaDqLgBxIQH2MLUOIMAAvHwyXyFSB54AAAAASUVORK5CYII=);
      margin-bottom: 35px;
      display: none; }
      @media (max-width: 991px) {
        .indexPage .item3 ul li {
          width: calc((100% - 24px) / 2); } }
      @media (max-width: 767px) {
        .indexPage .item3 ul li {
          width: 100%;
          padding: 17px; }
          .indexPage .item3 ul li:not(:last-child) {
            margin-bottom: 52px; } }
      .indexPage .item3 ul li:nth-child(1) {
        display: block; }
      .indexPage .item3 ul li:nth-child(2) {
        display: block; }
      .indexPage .item3 ul li:nth-child(3) {
        display: block; }
      @media (min-width: 768px) {
        .indexPage .item3 ul li:hover .hov-bx {
          opacity: 1; } }
      @media (min-width: 992px) {
        .indexPage .item3 ul li:not(:nth-child(3n)) {
          margin-right: 16px; } }
      @media (max-width: 991px) {
        .indexPage .item3 ul li:not(:nth-child(even)) {
          margin-right: 24px; } }
      @media (max-width: 767px) {
        .indexPage .item3 ul li:not(:nth-child(even)) {
          margin-right: 0px; } }
      .indexPage .item3 ul li .img-bx {
        position: relative; }
        .indexPage .item3 ul li .img-bx::before {
          position: absolute;
          content: "";
          bottom: 0;
          left: 0;
          width: 100%;
          height: 220px;
          background-image: linear-gradient(to top, #171613, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)); }
        .indexPage .item3 ul li .img-bx .bgcover {
          padding-bottom: 133%;
          background-size: cover !important; }
          @media (max-width: 767px) {
            .indexPage .item3 ul li .img-bx .bgcover {
              padding-bottom: 93%; } }
        .indexPage .item3 ul li .img-bx .des-bx {
          position: absolute;
          bottom: 22px;
          left: 50%;
          transform: translateX(-50%);
          width: calc(100% - 44px); }
          .indexPage .item3 ul li .img-bx .des-bx .ti {
            display: flex;
            padding-bottom: 14px;
            margin-bottom: 15px;
            border-bottom: 1px solid #fff;
            align-items: flex-end; }
            .indexPage .item3 ul li .img-bx .des-bx .ti .na {
              font-size: 1.625rem;
              line-height: 1.4;
              letter-spacing: 1px;
              font-weight: 300;
              color: #fff; }
            .indexPage .item3 ul li .img-bx .des-bx .ti .des {
              margin-left: 8px;
              font-size: 1rem;
              line-height: 1.8;
              letter-spacing: 1px;
              font-weight: 300;
              color: #fff; }
          .indexPage .item3 ul li .img-bx .des-bx .sh-des {
            color: #fff; }
      .indexPage .item3 ul li .hov-bx {
        opacity: 0;
        transition: 0.4s ease;
        position: absolute;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        top: 20px;
        left: 20px;
        background-color: #FCAD28;
        padding: 25px 22px; }
        @media (max-width: 767px) {
          .indexPage .item3 ul li .hov-bx {
            opacity: 1;
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            padding: 16px 30px 26px 30px; } }
        .indexPage .item3 ul li .hov-bx .ti {
          display: flex;
          padding-bottom: 14px;
          margin-bottom: 15px;
          border-bottom: 1px solid #fff;
          align-items: flex-end; }
          @media (max-width: 767px) {
            .indexPage .item3 ul li .hov-bx .ti {
              display: none; } }
          .indexPage .item3 ul li .hov-bx .ti .na {
            font-size: 1.625rem;
            line-height: 1.4;
            letter-spacing: 1px;
            font-weight: 300;
            color: #fff; }
          .indexPage .item3 ul li .hov-bx .ti .des {
            margin-left: 8px;
            font-size: 1rem;
            line-height: 1.8;
            letter-spacing: 1px;
            font-weight: 300;
            color: #fff; }
        .indexPage .item3 ul li .hov-bx .ed-bx {
          min-height: 253px;
          position: relative;
          overflow: hidden;
          margin-bottom: 15px; }
          @media (max-width: 767px) {
            .indexPage .item3 ul li .hov-bx .ed-bx {
              min-height: 100%;
              margin-bottom: 17px; } }
          .indexPage .item3 ul li .hov-bx .ed-bx .editor_Content {
            color: #fff; }
            @media (max-width: 767px) {
              .indexPage .item3 ul li .hov-bx .ed-bx .editor_Content {
                font-size: 1.25rem; } }
        .indexPage .item3 ul li .hov-bx .sh-dotbtn {
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACdJREFUeNpiYCAW/P//vwGI9+NiM0I5CkBKgJGR8QI2NsOwtg4gwAC+gmKMgB1wpgAAAABJRU5ErkJggg==); }
          .indexPage .item3 ul li .hov-bx .sh-dotbtn .ins-bx {
            background: #FCAD28; }
          .indexPage .item3 ul li .hov-bx .sh-dotbtn span {
            color: #fff; }
            .indexPage .item3 ul li .hov-bx .sh-dotbtn span::before {
              background-color: #fff;
              right: 0;
              width: 57.5%; }
              @media (max-width: 767px) {
                .indexPage .item3 ul li .hov-bx .sh-dotbtn span::before {
                  width: 52.5%; } }
            .indexPage .item3 ul li .hov-bx .sh-dotbtn span::after {
              background-color: #fff;
              right: 0; }
  .indexPage .item3 .hid.it3-morebtn {
    display: none; }
  .indexPage .item3 .it3-morebtn {
    width: 252px;
    height: 64px;
    background: #82D1CB;
    text-align: center;
    display: block;
    margin: 0 auto; }
    @media (max-width: 767px) {
      .indexPage .item3 .it3-morebtn {
        width: 170px;
        height: 46px;
        margin-top: -15px; } }
    .indexPage .item3 .it3-morebtn span {
      font-size: 1rem;
      line-height: 3.9;
      letter-spacing: 2px;
      font-weight: 300;
      color: #fff;
      font-family: "Noto Sans TC"; }
      @media (max-width: 767px) {
        .indexPage .item3 .it3-morebtn span {
          line-height: 2.9; } }

.indexPage .item4 {
  position: relative;
  margin-top: 160px;
  padding: 80px 0; }
  @media (max-width: 991px) {
    .indexPage .item4 {
      margin-top: 60px;
      padding: 40px 0; } }
  .indexPage .item4 .bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 71.25vw;
    background: #82D1CB;
    z-index: 4; }
    @media (max-width: 991px) {
      .indexPage .item4 .bg {
        width: 100%;
        height: 70%; } }
    .indexPage .item4 .bg::before {
      position: absolute;
      content: "";
      bottom: 0;
      right: 800px;
      width: 560px;
      height: 480px;
      background: url(../images/point_2.png?3f5c7761) no-repeat center center/contain; }
      @media (max-width: 991px) {
        .indexPage .item4 .bg::before {
          display: none; } }
  .indexPage .item4 .it4-bx {
    width: 100%;
    max-width: 1580px;
    display: flex;
    margin-left: auto;
    position: relative;
    z-index: 5;
    padding-left: 15px; }
    @media (max-width: 991px) {
      .indexPage .item4 .it4-bx {
        display: block;
        padding: 0 25px; } }
    @media (min-width: 992px) {
      .indexPage .item4 .it4-bx .ind-ti {
        text-align: left;
        width: calc(100% - 745px);
        padding-right: 15px; } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .indexPage .item4 .it4-bx .ind-ti {
      width: calc(100% - 600px); } }
    @media (min-width: 992px) {
        .indexPage .item4 .it4-bx .ind-ti .ti {
          color: #fff; }
        .indexPage .item4 .it4-bx .ind-ti .e-ti {
          padding-bottom: 0px;
          color: #fff;
          font-size: 6.25rem; } }
    @media (min-width: 992px) and (max-width: 1500px) {
      .indexPage .item4 .it4-bx .ind-ti .e-ti {
        font-size: 4.6875rem; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      .indexPage .item4 .it4-bx .ind-ti .e-ti {
        font-size: 2.5rem; } }
    @media (min-width: 992px) {
          .indexPage .item4 .it4-bx .ind-ti .e-ti::before {
            display: none; }
        .indexPage .item4 .it4-bx .ind-ti .sh-des {
          padding-left: 176px;
          position: relative;
          color: #fff; } }
    @media (min-width: 992px) and (max-width: 1500px) {
      .indexPage .item4 .it4-bx .ind-ti .sh-des {
        padding-left: 116px; } }
    @media (min-width: 992px) {
          .indexPage .item4 .it4-bx .ind-ti .sh-des::before {
            position: absolute;
            content: "";
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 160px;
            height: 1px;
            background-color: #fff; } }
      @media (min-width: 992px) and (max-width: 1500px) {
        .indexPage .item4 .it4-bx .ind-ti .sh-des::before {
          width: 100px; } }
    @media (max-width: 991px) {
      .indexPage .item4 .it4-bx .ind-ti .ti {
        color: #fff; }
      .indexPage .item4 .it4-bx .ind-ti .e-ti {
        color: #fff; }
        .indexPage .item4 .it4-bx .ind-ti .e-ti::before {
          background-color: #fff;
          bottom: -6px; }
      .indexPage .item4 .it4-bx .ind-ti .sh-des {
        color: #fff; } }
    .indexPage .item4 .it4-bx ul {
      width: 745px; }
      @media (max-width: 1200px) {
        .indexPage .item4 .it4-bx ul {
          width: 600px; } }
      @media (max-width: 991px) {
        .indexPage .item4 .it4-bx ul {
          width: 100%;
          margin-top: 44px; } }
      .indexPage .item4 .it4-bx ul a {
        display: block;
        position: relative; }
        .indexPage .item4 .it4-bx ul a::before {
          position: absolute;
          content: "";
          bottom: 0;
          left: 0;
          width: 100%;
          height: 65px;
          background-image: linear-gradient(0deg, rgba(68, 68, 68, 0.6), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)); }
        .indexPage .item4 .it4-bx ul a .ti {
          position: absolute;
          bottom: 15px;
          left: 25px;
          font-size: 1.25rem;
          line-height: 1.5;
          letter-spacing: 1px;
          font-weight: 400;
          color: #fff; }
        .indexPage .item4 .it4-bx ul a .bgcover {
          width: 400px;
          padding-bottom: 320px;
          background-size: cover !important; }
          @media (max-width: 1200px) {
            .indexPage .item4 .it4-bx ul a .bgcover {
              width: 330px;
              padding-bottom: 230px; } }
          @media (max-width: 991px) {
            .indexPage .item4 .it4-bx ul a .bgcover {
              width: 100%;
              padding-bottom: 80%; } }
      .indexPage .item4 .it4-bx ul .slick-prev {
        width: 64px;
        height: 64px;
        background-color: #82D1CB;
        transition: 0.4s ease;
        top: auto;
        transform: none;
        bottom: 0;
        left: -174px;
        border: 1px solid #fff; }
        @media (max-width: 767px) {
          .indexPage .item4 .it4-bx ul .slick-prev {
            width: 40px;
            height: 40px;
            bottom: auto;
            top: 50%;
            transform: translateY(-50%);
            left: 0px; } }
        .indexPage .item4 .it4-bx ul .slick-prev::before, .indexPage .item4 .it4-bx ul .slick-prev::after {
          position: absolute;
          content: "";
          width: 1px;
          height: 20px;
          background-color: #fff;
          left: 50%;
          transition: 0.4s ease; }
          @media (max-width: 767px) {
            .indexPage .item4 .it4-bx ul .slick-prev::before, .indexPage .item4 .it4-bx ul .slick-prev::after {
              height: 16px; } }
        .indexPage .item4 .it4-bx ul .slick-prev::before {
          top: 17px;
          transform: translateX(-50%) rotate(45deg); }
          @media (max-width: 767px) {
            .indexPage .item4 .it4-bx ul .slick-prev::before {
              top: 6px; } }
        .indexPage .item4 .it4-bx ul .slick-prev::after {
          top: 31px;
          transform: translateX(-50%) rotate(-45deg); }
          @media (max-width: 767px) {
            .indexPage .item4 .it4-bx ul .slick-prev::after {
              top: 17px; } }
        @media (min-width: 768px) {
          .indexPage .item4 .it4-bx ul .slick-prev:hover {
            background-color: #fff; }
            .indexPage .item4 .it4-bx ul .slick-prev:hover::before, .indexPage .item4 .it4-bx ul .slick-prev:hover::after {
              background-color: #82D1CB; } }
      .indexPage .item4 .it4-bx ul .slick-next {
        width: 64px;
        height: 64px;
        background-color: #82D1CB;
        transition: 0.4s ease;
        top: auto;
        right: auto;
        transform: none;
        bottom: 0;
        left: -110px;
        border: 1px solid #fff;
        border-left: none; }
        @media (max-width: 767px) {
          .indexPage .item4 .it4-bx ul .slick-next {
            width: 40px;
            height: 40px;
            bottom: auto;
            left: auto;
            top: 50%;
            right: 0px;
            transform: translateY(-50%);
            border-left: 1px solid #fff; } }
        .indexPage .item4 .it4-bx ul .slick-next::before, .indexPage .item4 .it4-bx ul .slick-next::after {
          position: absolute;
          content: "";
          width: 1px;
          height: 20px;
          background-color: #fff;
          left: 50%;
          transition: 0.4s ease; }
          @media (max-width: 767px) {
            .indexPage .item4 .it4-bx ul .slick-next::before, .indexPage .item4 .it4-bx ul .slick-next::after {
              height: 16px; } }
        .indexPage .item4 .it4-bx ul .slick-next::before {
          top: 17px;
          transform: translateX(-50%) rotate(-45deg); }
          @media (max-width: 767px) {
            .indexPage .item4 .it4-bx ul .slick-next::before {
              top: 6px; } }
        .indexPage .item4 .it4-bx ul .slick-next::after {
          top: 31px;
          transform: translateX(-50%) rotate(45deg); }
          @media (max-width: 767px) {
            .indexPage .item4 .it4-bx ul .slick-next::after {
              top: 17px; } }
        @media (min-width: 768px) {
          .indexPage .item4 .it4-bx ul .slick-next:hover {
            background-color: #fff; }
            .indexPage .item4 .it4-bx ul .slick-next:hover::before, .indexPage .item4 .it4-bx ul .slick-next:hover::after {
              background-color: #82D1CB; } }

.indexPage .item5 {
  padding-top: 160px; }
  @media (max-width: 991px) {
    .indexPage .item5 {
      padding-top: 0px; } }
  .indexPage .item5 .map-bx {
    width: 100%;
    height: 520px;
    position: relative;
    z-index: 3; }
    @media (max-width: 991px) {
      .indexPage .item5 .map-bx {
        height: 270px; } }
    .indexPage .item5 .map-bx iframe {
      width: 100%;
      height: 100%; }
  .indexPage .item5 .it5-bx {
    display: flex;
    justify-content: space-between;
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    margin-top: 60px; }
    @media (max-width: 991px) {
      .indexPage .item5 .it5-bx {
        display: block;
        margin-top: 40px; } }
    .indexPage .item5 .it5-bx .t-bx {
      margin-bottom: 45px; }
      @media (max-width: 991px) {
        .indexPage .item5 .it5-bx .t-bx {
          margin-bottom: 20px; } }
      .indexPage .item5 .it5-bx .t-bx .e-ti {
        font-size: 3rem;
        line-height: 1.2;
        letter-spacing: 0px;
        font-weight: 300;
        color: #82D1CB; }
        @media (max-width: 991px) {
          .indexPage .item5 .it5-bx .t-bx .e-ti {
            font-size: 2rem; } }
      .indexPage .item5 .it5-bx .t-bx .ti {
        font-size: 1.5rem;
        line-height: 1.2;
        letter-spacing: 2px;
        font-weight: 300;
        color: #000000;
        position: relative;
        padding-bottom: 20px; }
        @media (max-width: 991px) {
          .indexPage .item5 .it5-bx .t-bx .ti {
            margin-top: 3px;
            font-size: 1.25rem;
            padding-bottom: 16px; } }
        .indexPage .item5 .it5-bx .t-bx .ti::before {
          position: absolute;
          content: "";
          bottom: 0;
          left: 0;
          width: 40px;
          height: 1px;
          background-color: #82D1CB; }
    .indexPage .item5 .it5-bx .l {
      width: 49%;
      padding-right: 5%; }
      @media (max-width: 991px) {
        .indexPage .item5 .it5-bx .l {
          width: 100%;
          padding-right: 0; } }
    .indexPage .item5 .it5-bx .r {
      width: 49%; }
      @media (max-width: 991px) {
        .indexPage .item5 .it5-bx .r {
          width: 100%;
          margin-top: 38px; } }

.indexPage .item6 {
  margin-top: 50px;
  padding-top: 123px;
  padding-bottom: 123px;
  background: url(../images/bg_1.jpg?f445f821) no-repeat center center/cover; }
  @media (max-width: 767px) {
    .indexPage .item6 {
      padding-top: 64px;
      padding-bottom: 63px;
      background: url(../images/bg_mb.jpg?8c43702a) no-repeat center center/cover; } }
  .indexPage .item6 .it6-bx .ind-ti .ti {
    color: #fff; }
  .indexPage .item6 .it6-bx .ind-ti .e-ti {
    color: #fff;
    font-size: 6.25rem; }
    .indexPage .item6 .it6-bx .ind-ti .e-ti::before {
      background-color: #fff; }
    @media (max-width: 767px) {
      .indexPage .item6 .it6-bx .ind-ti .e-ti {
        font-size: 2.5rem; } }
  .indexPage .item6 .it6-bx .ind-ti .sh-des {
    color: #fff; }
  .indexPage .item6 .it6-bx .flex-bx {
    display: flex;
    justify-content: space-between;
    margin-top: 55px; }
    @media (max-width: 767px) {
      .indexPage .item6 .it6-bx .flex-bx {
        margin-top: 23px;
        display: block; } }
    .indexPage .item6 .it6-bx .flex-bx .sh-dotbtn {
      max-width: 49%;
      height: 120px;
      transition: 0.4s ease; }
      @media (max-width: 767px) {
        .indexPage .item6 .it6-bx .flex-bx .sh-dotbtn {
          max-width: 100%;
          height: 60px; } }
      @media (min-width: 768px) {
        .indexPage .item6 .it6-bx .flex-bx .sh-dotbtn:hover {
          opacity: 0.8; } }
      @media (max-width: 767px) {
        .indexPage .item6 .it6-bx .flex-bx .sh-dotbtn:nth-child(1) {
          margin-bottom: 10px; } }
      .indexPage .item6 .it6-bx .flex-bx .sh-dotbtn:nth-child(1) .ins-bx {
        background: #FCAD28; }
      .indexPage .item6 .it6-bx .flex-bx .sh-dotbtn:nth-child(2) {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACNJREFUeNpiYCAWNF083QDE+3GxWaDqFgDxBgLsYWodQIABAIQpPTDltIWMAAAAAElFTkSuQmCC); }
        .indexPage .item6 .it6-bx .flex-bx .sh-dotbtn:nth-child(2) .ins-bx {
          background: #82D1CB; }
      .indexPage .item6 .it6-bx .flex-bx .sh-dotbtn span {
        text-align: center;
        line-height: 0.3;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        line-height: 1.5;
        letter-spacing: 13px;
        font-weight: 300;
        color: #fff; }
        .indexPage .item6 .it6-bx .flex-bx .sh-dotbtn span img {
          margin-right: 45px; }
          @media (max-width: 767px) {
            .indexPage .item6 .it6-bx .flex-bx .sh-dotbtn span img {
              width: 24px;
              margin-right: 28px; } }
        .indexPage .item6 .it6-bx .flex-bx .sh-dotbtn span::before, .indexPage .item6 .it6-bx .flex-bx .sh-dotbtn span::after {
          display: none; }
        @media (max-width: 767px) {
          .indexPage .item6 .it6-bx .flex-bx .sh-dotbtn span {
            font-size: 1rem; } }

@media (min-width: 1200px) {
  .teamPage .container {
    max-width: 1056px; } }

.teamPage .item1 {
  padding-top: 100px;
  padding-bottom: 100px; }
  @media (max-width: 991px) {
    .teamPage .item1 {
      padding-top: 50px;
      padding-bottom: 50px; } }
  .teamPage .item1 .it1-bx {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .teamPage .item1 .it1-bx {
        display: block; } }
    .teamPage .item1 .it1-bx .t-img {
      order: 2;
      width: 356px; }
      @media (max-width: 991px) {
        .teamPage .item1 .it1-bx .t-img {
          margin: 0 auto; } }
      @media (max-width: 767px) {
        .teamPage .item1 .it1-bx .t-img {
          width: 100%; } }
      .teamPage .item1 .it1-bx .t-img .bgcover {
        padding-bottom: 472px;
        background-size: cover !important; }
        @media (max-width: 767px) {
          .teamPage .item1 .it1-bx .t-img .bgcover {
            padding-bottom: 132%; } }
    .teamPage .item1 .it1-bx .b-bx {
      width: calc(100% - 356px);
      padding-right: 10%; }
      @media (max-width: 991px) {
        .teamPage .item1 .it1-bx .b-bx {
          width: 100%;
          padding-right: 0;
          margin-top: 22px; } }
      .teamPage .item1 .it1-bx .b-bx .ti-bx {
        display: flex;
        padding-bottom: 15px;
        position: relative; }
        @media (max-width: 991px) {
          .teamPage .item1 .it1-bx .b-bx .ti-bx {
            padding-bottom: 10px; } }
        .teamPage .item1 .it1-bx .b-bx .ti-bx::before {
          position: absolute;
          content: "";
          bottom: 0;
          left: 0;
          width: 40px;
          height: 1px;
          background-color: #82D1CB; }
          @media (max-width: 991px) {
            .teamPage .item1 .it1-bx .b-bx .ti-bx::before {
              width: 20px; } }
        .teamPage .item1 .it1-bx .b-bx .ti-bx div {
          font-size: 1.5rem;
          line-height: 1.3;
          letter-spacing: 1px;
          font-weight: 400;
          color: #000000; }
      .teamPage .item1 .it1-bx .b-bx .editor_Content {
        margin-top: 30px; }
        @media (max-width: 991px) {
          .teamPage .item1 .it1-bx .b-bx .editor_Content {
            margin-top: 10px; } }

.teamPage .item2 {
  padding-top: 100px;
  padding-bottom: 110px;
  background-color: #fbfbfb; }
  @media (max-width: 991px) {
    .teamPage .item2 {
      padding-top: 50px;
      padding-bottom: 55px; } }
  .teamPage .item2 .sh-dotbtn {
    margin-top: 70px; }
    @media (max-width: 991px) {
      .teamPage .item2 .sh-dotbtn {
        margin-top: 30px; } }
    @media (min-width: 768px) {
      .teamPage .item2 .sh-dotbtn:hover .ins-bx {
        background-color: #FCAD28; }
      .teamPage .item2 .sh-dotbtn:hover .ins-bx span {
        color: #fff; }
        .teamPage .item2 .sh-dotbtn:hover .ins-bx span::before {
          background-color: #fff;
          left: 0;
          width: calc(58% + 5px); }
        .teamPage .item2 .sh-dotbtn:hover .ins-bx span::after {
          background-color: #fff;
          left: 0; } }
    .teamPage .item2 .sh-dotbtn .ins-bx {
      background-color: #fff; }
      .teamPage .item2 .sh-dotbtn .ins-bx span {
        color: #FCAD28;
        text-align: right;
        padding-right: 40px; }
        @media (max-width: 767px) {
          .teamPage .item2 .sh-dotbtn .ins-bx span {
            padding-right: 10px; } }
        .teamPage .item2 .sh-dotbtn .ins-bx span::before, .teamPage .item2 .sh-dotbtn .ins-bx span::after {
          background-color: #FCAD28; }
        .teamPage .item2 .sh-dotbtn .ins-bx span::before {
          left: 40px;
          right: auto; }
          @media (max-width: 767px) {
            .teamPage .item2 .sh-dotbtn .ins-bx span::before {
              left: 0;
              width: calc(58.5% + 30px); } }
        .teamPage .item2 .sh-dotbtn .ins-bx span::after {
          right: auto;
          left: 40px; }
          @media (max-width: 767px) {
            .teamPage .item2 .sh-dotbtn .ins-bx span::after {
              left: 0; } }

@media (min-width: 1200px) {
  .aboutPage .container {
    max-width: 1056px; } }

.aboutPage .item1 {
  padding-top: 100px;
  padding-bottom: 130px; }
  @media (max-width: 767px) {
    .aboutPage .item1 {
      padding-top: 50px;
      padding-bottom: 65px; } }
  .aboutPage .item1 ul li:not(:last-child) {
    margin-bottom: 80px; }
    @media (max-width: 767px) {
      .aboutPage .item1 ul li:not(:last-child) {
        margin-bottom: 30px; } }
  .aboutPage .item1 ul .ti-bx {
    padding-top: 12px;
    padding-bottom: 16px;
    padding-left: 32px;
    padding-right: 40px;
    font-size: 1.5rem;
    line-height: 1.4;
    letter-spacing: 1px;
    font-weight: 400;
    color: #000000;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    position: relative; }
    @media (max-width: 767px) {
      .aboutPage .item1 ul .ti-bx {
        font-size: 1.25rem;
        padding-left: 16px;
        padding-top: 6px;
        padding-bottom: 8px;
        padding-right: 20px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); } }
    .aboutPage .item1 ul .ti-bx::before {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 5px;
      height: calc(100% -20px);
      background-color: #FCAD28; }
      @media (max-width: 767px) {
        .aboutPage .item1 ul .ti-bx::before {
          width: 2px; } }
  .aboutPage .item1 ul .ed-bx {
    margin-top: 30px; }
    @media (max-width: 767px) {
      .aboutPage .item1 ul .ed-bx {
        margin-top: 22px; } }

.htmlPage .item1 {
  padding-top: 100px;
  padding-bottom: 130px; }
  @media (min-width: 1200px) {
    .htmlPage .item1 .container {
      max-width: 1056px; } }
  @media (max-width: 767px) {
    .htmlPage .item1 {
      padding-top: 50px;
      padding-bottom: 65px; } }
  .htmlPage .item1 ul li:not(:last-child) {
    margin-bottom: 80px; }
    @media (max-width: 767px) {
      .htmlPage .item1 ul li:not(:last-child) {
        margin-bottom: 30px; } }
  .htmlPage .item1 ul .ti-bx {
    padding-top: 12px;
    padding-bottom: 16px;
    padding-left: 32px;
    padding-right: 40px;
    font-size: 1.5rem;
    line-height: 1.4;
    letter-spacing: 1px;
    font-weight: 400;
    color: #000000;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    position: relative; }
    @media (max-width: 767px) {
      .htmlPage .item1 ul .ti-bx {
        font-size: 1.5rem;
        padding-left: 16px;
        padding-top: 6px;
        padding-bottom: 8px;
        padding-right: 20px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); } }
    .htmlPage .item1 ul .ti-bx::before {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 5px;
      height: calc(100% -20px);
      background-color: #FCAD28; }
      @media (max-width: 767px) {
        .htmlPage .item1 ul .ti-bx::before {
          width: 2px; } }
  .htmlPage .item1 ul .ed-bx {
    margin-top: 30px; }
    @media (max-width: 767px) {
      .htmlPage .item1 ul .ed-bx {
        margin-top: 22px; } }
  .htmlPage .item1 .sh-dotbtn {
    margin-top: 65px; }
    @media (max-width: 991px) {
      .htmlPage .item1 .sh-dotbtn {
        margin-top: 30px; } }
    @media (min-width: 768px) {
      .htmlPage .item1 .sh-dotbtn:hover .ins-bx {
        background-color: #FCAD28; }
      .htmlPage .item1 .sh-dotbtn:hover .ins-bx span {
        color: #fff; }
        .htmlPage .item1 .sh-dotbtn:hover .ins-bx span::before {
          background-color: #fff;
          left: 0;
          width: calc(58% + 5px); }
        .htmlPage .item1 .sh-dotbtn:hover .ins-bx span::after {
          background-color: #fff;
          left: 0; } }
    .htmlPage .item1 .sh-dotbtn .ins-bx {
      background-color: #fff; }
      .htmlPage .item1 .sh-dotbtn .ins-bx span {
        color: #FCAD28;
        text-align: right;
        padding-right: 40px; }
        @media (max-width: 767px) {
          .htmlPage .item1 .sh-dotbtn .ins-bx span {
            padding-right: 10px; } }
        .htmlPage .item1 .sh-dotbtn .ins-bx span::before, .htmlPage .item1 .sh-dotbtn .ins-bx span::after {
          background-color: #FCAD28; }
        .htmlPage .item1 .sh-dotbtn .ins-bx span::before {
          left: 40px;
          right: auto; }
          @media (max-width: 767px) {
            .htmlPage .item1 .sh-dotbtn .ins-bx span::before {
              left: 0;
              width: calc(58.5% + 30px); } }
        .htmlPage .item1 .sh-dotbtn .ins-bx span::after {
          right: auto;
          left: 40px; }
          @media (max-width: 767px) {
            .htmlPage .item1 .sh-dotbtn .ins-bx span::after {
              left: 0; } }

.servicePage .item0 {
  padding-top: 100px; }
  @media (max-width: 767px) {
    .servicePage .item0 {
      padding-top: 50px; } }

.servicePage .item1 {
  padding-top: 72px; }
  @media (max-width: 767px) {
    .servicePage .item1 {
      padding-top: 35px; } }

.casedetailPage .item1 ul .ed-bx,
.newsdetailPage .item1 ul .ed-bx {
  margin-top: 48px; }
  @media (max-width: 767px) {
    .casedetailPage .item1 ul .ed-bx,
    .newsdetailPage .item1 ul .ed-bx {
      margin-top: 30px; } }

.newsdetailPage .item1 ul .ti-bx .t-flex {
  display: flex;
  align-items: center;
  margin-bottom: 3px; }
  @media (max-width: 767px) {
    .newsdetailPage .item1 ul .ti-bx .t-flex {
      margin-bottom: 5px; } }
  .newsdetailPage .item1 ul .ti-bx .t-flex .ty-ti {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.5px;
    font-weight: 400;
    color: #82D1CB; }
  .newsdetailPage .item1 ul .ti-bx .t-flex .da {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.5px;
    font-weight: 300;
    color: #707070;
    padding-left: 33px;
    position: relative; }
    .newsdetailPage .item1 ul .ti-bx .t-flex .da::before {
      position: absolute;
      content: "";
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      width: 16px;
      height: 1px;
      background-color: #707070; }

.newsPage .item0 {
  padding-top: 100px; }
  @media (max-width: 767px) {
    .newsPage .item0 {
      padding-top: 50px; } }

.newsPage .item1 {
  padding-top: 72px;
  padding-bottom: 132px; }
  @media (max-width: 767px) {
    .newsPage .item1 {
      padding-top: 25px;
      padding-bottom: 15px; } }
  @media (min-width: 1200px) {
    .newsPage .item1 .container {
      max-width: 1056px; } }
  .newsPage .item1 .news-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .newsPage .item1 .news-list li {
      width: 45%;
      margin-bottom: 48px; }
      @media (max-width: 767px) {
        .newsPage .item1 .news-list li {
          width: 100%;
          margin-bottom: 40px; }
          .newsPage .item1 .news-list li:last-child {
            margin-bottom: 0; } }
      @media (min-width: 768px) {
        .newsPage .item1 .news-list li:last-child {
          margin-bottom: 0; }
        .newsPage .item1 .news-list li:nth-last-child(2) {
          margin-bottom: 0; } }
      .newsPage .item1 .news-list li a {
        display: block; }
        @media (min-width: 768px) {
          .newsPage .item1 .news-list li a:hover .ti {
            color: #FCAD28; } }
        .newsPage .item1 .news-list li a .t-flex {
          display: flex;
          align-items: center;
          margin-bottom: 3px; }
          @media (max-width: 767px) {
            .newsPage .item1 .news-list li a .t-flex {
              margin-bottom: 5px; } }
          .newsPage .item1 .news-list li a .t-flex .ty-ti {
            font-size: 1rem;
            line-height: 1.5;
            letter-spacing: 0.5px;
            font-weight: 400;
            color: #82D1CB; }
          .newsPage .item1 .news-list li a .t-flex .da {
            font-size: 1rem;
            line-height: 1.5;
            letter-spacing: 0.5px;
            font-weight: 300;
            color: #707070;
            padding-left: 33px;
            position: relative; }
            .newsPage .item1 .news-list li a .t-flex .da::before {
              position: absolute;
              content: "";
              top: 50%;
              left: 8px;
              transform: translateY(-50%);
              width: 16px;
              height: 1px;
              background-color: #707070; }
        .newsPage .item1 .news-list li a .ti {
          font-size: 1.25rem;
          line-height: 1.5;
          letter-spacing: 0.5px;
          font-weight: 400;
          color: #000000;
          transition: 0.4s ease; }
  @media (max-width: 767px) {
    .newsPage .item1 .page {
      margin-top: 30px; } }

.casePage .item1 {
  padding-top: 100px;
  padding-bottom: 130px; }
  @media (max-width: 767px) {
    .casePage .item1 {
      padding-top: 50px;
      padding-bottom: 5px; } }
  .casePage .item1 .serlist {
    display: flex;
    flex-wrap: wrap; }
    .casePage .item1 .serlist li {
      width: calc((100% - 32px) / 3);
      padding: 0;
      margin-bottom: 70px; }
      @media (max-width: 1199px) and (min-width: 992px) {
        .casePage .item1 .serlist li {
          width: calc((100% - 20px) / 2); }
          .casePage .item1 .serlist li:not(:nth-child(even)) {
            margin-right: 20px; } }
      @media (max-width: 991px) {
        .casePage .item1 .serlist li {
          width: 100%; } }
      @media (max-width: 767px) {
        .casePage .item1 .serlist li {
          margin-bottom: 35px; } }
      @media (min-width: 1200px) {
        .casePage .item1 .serlist li:not(:nth-child(3n)) {
          margin-right: 16px; } }
      .casePage .item1 .serlist li .des-bx .t {
        height: 30px;
        position: relative;
        overflow: hidden; }
      .casePage .item1 .serlist li .des-bx .sh-des {
        height: 3em;
        line-height: 1.5;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* 第幾行要顯示點點點 */
        -webkit-box-orient: vertical;
        margin-bottom: 3px; }
      .casePage .item1 .serlist li .des-bx .tag-bx {
        display: flex;
        align-items: center;
        flex-wrap: wrap; }
        .casePage .item1 .serlist li .des-bx .tag-bx .tag {
          font-size: 1rem;
          line-height: 1.5;
          letter-spacing: 0.5px;
          font-weight: 300;
          color: #82D1CB; }
          .casePage .item1 .serlist li .des-bx .tag-bx .tag:not(:last-child) {
            margin-right: 6px; }
          @media (max-width: 767px) {
            .casePage .item1 .serlist li .des-bx .tag-bx .tag {
              font-size: 1.25rem;
              line-height: 1.5;
              letter-spacing: 0.5px;
              font-weight: 300;
              color: #82D1CB; } }
      .casePage .item1 .serlist li .des-bx .sh-dotbtn {
        margin-top: 30px; }
        @media (max-width: 767px) {
          .casePage .item1 .serlist li .des-bx .sh-dotbtn {
            margin-top: 25px; } }
      @media (max-width: 400px) {
        .casePage .item1 .serlist li .sh-dotbtn span:before {
          right: 15px; } }
      @media (max-width: 350px) {
        .casePage .item1 .serlist li .sh-dotbtn span:before {
          right: 0px;
          width: calc(43.5% + 15px); } }
      @media (max-width: 400px) {
        .casePage .item1 .serlist li .sh-dotbtn span::after {
          right: 15px; } }
      @media (max-width: 350px) {
        .casePage .item1 .serlist li .sh-dotbtn span::after {
          right: 0px; } }
  .casePage .item1 .page {
    margin-top: 10px; }

@media (max-width: 767px) {
  .contactPage .sh-banner .l-card .e-ti {
    font-size: 1.6875rem; } }

.contactPage .item1 {
  padding-top: 100px; }
  @media (max-width: 767px) {
    .contactPage .item1 {
      padding-top: 50px; } }
  .contactPage .item1 .ti {
    padding-bottom: 100px;
    position: relative;
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: 2px;
    font-weight: 300;
    color: #000000; }
    @media (max-width: 767px) {
      .contactPage .item1 .ti {
        padding-bottom: 65px; } }
    .contactPage .item1 .ti::before {
      position: absolute;
      content: "";
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 80px;
      background-color: #FCAD28; }
      @media (max-width: 767px) {
        .contactPage .item1 .ti::before {
          height: 50px; } }
  .contactPage .item1 .form-bx {
    max-width: 816px;
    width: 100%;
    margin: 0 auto;
    margin-top: 15px; }
    .contactPage .item1 .form-bx .form-group {
      margin-bottom: 24px; }
    .contactPage .item1 .form-bx label {
      display: flex;
      justify-content: flex-start;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 1px;
      font-weight: 300;
      color: #000000;
      margin-bottom: 4px; }
      @media (max-width: 767px) {
        .contactPage .item1 .form-bx label {
          font-size: 1.25rem;
          line-height: 1.5;
          letter-spacing: 1px;
          font-weight: 300;
          color: #000000; } }
      .contactPage .item1 .form-bx label i {
        color: #ff0000; }
    .contactPage .item1 .form-bx input {
      width: 100%;
      border-radius: 0;
      border: 1px solid #cccccc;
      border-radius: 0;
      height: 48px;
      padding-left: 20px;
      padding-right: 0; }
    .contactPage .item1 .form-bx .help-block {
      margin-top: 3px;
      font-size: 0.875rem;
      line-height: 1.5;
      letter-spacing: 1px;
      font-weight: 300;
      color: #FCAD28; }
    .contactPage .item1 .form-bx .rad_content {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      width: 100%;
      margin-top: 5px; }
      @media (max-width: 767px) {
        .contactPage .item1 .form-bx .rad_content {
          width: 100%;
          margin-top: 10px; } }
      .contactPage .item1 .form-bx .rad_content input {
        display: none; }
      .contactPage .item1 .form-bx .rad_content label {
        padding-left: 22px;
        margin-bottom: 0;
        cursor: pointer;
        width: auto;
        min-width: auto; }
        @media (max-width: 767px) {
          .contactPage .item1 .form-bx .rad_content label {
            padding-left: 20px;
            margin-bottom: 0; } }
      .contactPage .item1 .form-bx .rad_content input[type="radio"] + label:before {
        width: 18px;
        height: 18px;
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 100px;
        position: absolute;
        top: 3px;
        left: 0;
        content: ""; }
        @media (max-width: 767px) {
          .contactPage .item1 .form-bx .rad_content input[type="radio"] + label:before {
            width: 15px;
            height: 15px;
            top: 8px; } }
      .contactPage .item1 .form-bx .rad_content input[type="radio"]:checked + label:after {
        width: 10px;
        height: 10px;
        background-color: #82D1CB;
        border-radius: 100px;
        position: absolute;
        content: "";
        top: 50%;
        left: 4.5px;
        transform: translateY(-50%); }
        @media (max-width: 767px) {
          .contactPage .item1 .form-bx .rad_content input[type="radio"]:checked + label:after {
            top: 15px;
            left: 4px;
            width: 7px;
            height: 7px; } }
      .contactPage .item1 .form-bx .rad_content .radio {
        position: relative;
        margin-right: 0px; }
        .contactPage .item1 .form-bx .rad_content .radio:not(:last-child) {
          margin-right: 30px; }
        @media (max-width: 767px) {
          .contactPage .item1 .form-bx .rad_content .radio {
            width: auto;
            margin-right: 0px; } }
    .contactPage .item1 .form-bx .textarea-bx {
      margin-top: -10px;
      display: block; }
      .contactPage .item1 .form-bx .textarea-bx textarea {
        height: 335px;
        border: 1px solid #cccccc;
        padding-left: 20px;
        padding-top: 12px;
        border-radius: 0;
        resize: none; }
        @media (max-width: 767px) {
          .contactPage .item1 .form-bx .textarea-bx textarea {
            height: 115px; } }
    .contactPage .item1 .form-bx .res-bx {
      padding-left: 22px;
      display: none; }
      .contactPage .item1 .form-bx .res-bx .form-group:first-child {
        padding-bottom: 20px;
        border-bottom: 1px solid #cccccc; }
      .contactPage .item1 .form-bx .res-bx .form-group > label {
        margin-bottom: 8px; }
      .contactPage .item1 .form-bx .res-bx .othinp-bx {
        display: flex; }
      .contactPage .item1 .form-bx .res-bx .othinput {
        width: 180px;
        height: 23px;
        border: none;
        display: block;
        border-bottom: 1px solid #cccccc;
        padding-left: 5px; }
    .contactPage .item1 .form-bx .send-btn {
      margin-top: 60px; }
      @media (max-width: 767px) {
        .contactPage .item1 .form-bx .send-btn {
          margin-top: 40px; } }
      @media (min-width: 768px) {
        .contactPage .item1 .form-bx .send-btn:hover span:before {
          width: calc(77.5% - 40px); } }

.contactPage .item2 {
  padding-top: 120px; }
  @media (max-width: 767px) {
    .contactPage .item2 {
      padding-top: 73px; } }
  .contactPage .item2 .it2-bx {
    position: relative; }
    .contactPage .item2 .it2-bx .t {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      max-width: 564px;
      padding: 43px 0;
      padding-left: 100px;
      padding-right: 15px;
      background-color: rgba(255, 255, 255, 0.85);
      box-shadow: 0 0 24px rgba(0, 0, 0, 0.15); }
      @media (max-width: 991px) {
        .contactPage .item2 .it2-bx .t {
          max-width: 450px;
          padding-left: 50px; } }
      @media (max-width: 767px) {
        .contactPage .item2 .it2-bx .t {
          position: relative;
          top: auto;
          transform: none;
          max-width: 100%;
          padding-top: 25px;
          padding-left: 30px;
          padding-right: 30px;
          padding-bottom: 25px;
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.15); } }
      .contactPage .item2 .it2-bx .t .ti {
        font-size: 1.5rem;
        line-height: 1.3;
        letter-spacing: 2px;
        font-weight: 300;
        color: #000000;
        padding-bottom: 15px;
        margin-bottom: 25px;
        position: relative; }
        @media (max-width: 767px) {
          .contactPage .item2 .it2-bx .t .ti {
            margin-bottom: 15px;
            padding-bottom: 15px;
            font-size: 1.25rem; } }
        .contactPage .item2 .it2-bx .t .ti::before {
          position: absolute;
          content: "";
          bottom: 0;
          left: 0;
          width: 40px;
          height: 1px;
          background-color: #82D1CB; }
          @media (max-width: 767px) {
            .contactPage .item2 .it2-bx .t .ti::before {
              width: 20px; } }
      .contactPage .item2 .it2-bx .t ul li {
        padding-left: 55px;
        position: relative; }
        .contactPage .item2 .it2-bx .t ul li:not(:last-child) {
          margin-bottom: 24px; }
          @media (max-width: 767px) {
            .contactPage .item2 .it2-bx .t ul li:not(:last-child) {
              margin-bottom: 10px; } }
        @media (max-width: 767px) {
          .contactPage .item2 .it2-bx .t ul li {
            padding-left: 47px; } }
        .contactPage .item2 .it2-bx .t ul li img {
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          position: absolute; }
          @media (max-width: 767px) {
            .contactPage .item2 .it2-bx .t ul li img {
              width: 33px;
              top: 8px;
              transform: none; } }
        .contactPage .item2 .it2-bx .t ul li i {
          display: block;
          font-size: 1rem;
          line-height: 1.5;
          letter-spacing: 0.25px;
          font-weight: 400;
          color: #82D1CB; }
          @media (max-width: 767px) {
            .contactPage .item2 .it2-bx .t ul li i {
              font-size: 1.125rem;
              line-height: 1.5;
              letter-spacing: 0.25px;
              font-weight: 400;
              color: #82D1CB; } }
        .contactPage .item2 .it2-bx .t ul li span {
          font-size: 1rem;
          line-height: 1.5;
          letter-spacing: 0.25px;
          font-weight: 400;
          color: #000000; }
          @media (max-width: 767px) {
            .contactPage .item2 .it2-bx .t ul li span {
              font-size: 1.125rem;
              line-height: 1.5;
              letter-spacing: 0.25px;
              font-weight: 400;
              color: #000000; } }
    .contactPage .item2 .it2-bx .b {
      height: 640px;
      width: 66%;
      margin-left: auto; }
      @media (max-width: 767px) {
        .contactPage .item2 .it2-bx .b {
          width: 100%;
          margin: 0 auto;
          margin-top: 30px;
          height: 270px; } }
      @media (max-width: 575px) {
        .contactPage .item2 .it2-bx .b {
          width: 100vw;
          position: relative;
          left: -25px; } }
      .contactPage .item2 .it2-bx .b .iframe-bx,
      .contactPage .item2 .it2-bx .b iframe {
        height: 100%; }

.contactPage .item3 {
  padding-top: 70px;
  padding-bottom: 100px;
  position: relative; }
  @media (max-width: 991px) {
    .contactPage .item3 {
      padding-top: 0px;
      padding-bottom: 50px; } }
  .contactPage .item3 .bg {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(100% + 330px);
    background-color: #fbfbfb; }
    @media (max-width: 767px) {
      .contactPage .item3 .bg {
        height: calc(100% + 390px); } }
  .contactPage .item3 .it3-bx {
    display: flex;
    justify-content: space-between;
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    margin-top: 60px; }
    @media (max-width: 991px) {
      .contactPage .item3 .it3-bx {
        display: block;
        margin-top: 30px; } }
    .contactPage .item3 .it3-bx .t-bx {
      margin-bottom: 45px; }
      @media (max-width: 991px) {
        .contactPage .item3 .it3-bx .t-bx {
          margin-bottom: 20px; } }
      .contactPage .item3 .it3-bx .t-bx .e-ti {
        font-size: 3rem;
        line-height: 1.2;
        letter-spacing: 0px;
        font-weight: 300;
        color: #82D1CB; }
        @media (max-width: 991px) {
          .contactPage .item3 .it3-bx .t-bx .e-ti {
            font-size: 2rem; } }
      .contactPage .item3 .it3-bx .t-bx .ti {
        font-size: 1.5rem;
        line-height: 1.2;
        letter-spacing: 2px;
        font-weight: 300;
        color: #000000;
        position: relative;
        padding-bottom: 20px; }
        @media (max-width: 991px) {
          .contactPage .item3 .it3-bx .t-bx .ti {
            margin-top: 3px;
            font-size: 1.25rem;
            padding-bottom: 16px; } }
        .contactPage .item3 .it3-bx .t-bx .ti::before {
          position: absolute;
          content: "";
          bottom: 0;
          left: 0;
          width: 40px;
          height: 1px;
          background-color: #82D1CB; }
    .contactPage .item3 .it3-bx .l {
      width: 49%;
      padding-right: 5%; }
      @media (max-width: 991px) {
        .contactPage .item3 .it3-bx .l {
          width: 100%;
          padding-right: 0; } }
    .contactPage .item3 .it3-bx .r {
      width: 49%; }
      @media (max-width: 991px) {
        .contactPage .item3 .it3-bx .r {
          width: 100%;
          margin-top: 38px; } }

.searchPage .item0 {
  padding-top: 100px; }
  .searchPage .item0 .search-content {
    display: flex;
    align-items: center;
    padding: 0 88px;
    font-size: 1.25rem;
    margin-bottom: 25px; }
    .searchPage .item0 .search-content .iconwrap {
      margin-right: 15px; }
    .searchPage .item0 .search-content > div {
      color: #000;
      letter-spacing: 0.03em; }
      .searchPage .item0 .search-content > div:last-child {
        color: #82d1cb; }
    @media (max-width: 767px) {
      .searchPage .item0 .search-content {
        padding: 0; } }
  .searchPage .item0 #top-menu-ul {
    margin-bottom: 17px; }
    .searchPage .item0 #top-menu-ul .item_Menu {
      height: 48px; }
    .searchPage .item0 #top-menu-ul li {
      height: 48px; }
    .searchPage .item0 #top-menu-ul li a {
      font-size: 1.25rem;
      color: #82d1cb;
      letter-spacing: 0.2em;
      font-weight: 300;
      border: 1px solid #82d1cb;
      padding: 5px 15px;
      margin: 0 15px;
      border-radius: 25px; }
      .searchPage .item0 #top-menu-ul li a span {
        line-height: normal; }
    .searchPage .item0 #top-menu-ul li.active a {
      color: white;
      background-color: #fcad28;
      border: 1px solid #fcad28; }
    @media (min-width: 768px) {
      .searchPage .item0 #top-menu-ul li:hover a {
        color: white;
        background-color: #fcad28;
        border: 1px solid #fcad28; } }
  @media (max-width: 767px) {
    .searchPage .item0 {
      padding-top: 50px; } }

.searchPage .item1 .container {
  border-top: 1px solid #cccccc;
  padding-top: 57px; }
  @media (min-width: 1200px) {
    .searchPage .item1 .container {
      max-width: 1056px; } }

.searchPage .item1 .no-result {
  font-size: 1.25rem;
  text-align: center; }

.searchPage .item1 .news-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 155px; }
  .searchPage .item1 .news-list li {
    width: 45%;
    margin-bottom: 48px; }
    @media (max-width: 767px) {
      .searchPage .item1 .news-list li {
        width: 100%;
        margin-bottom: 40px; }
        .searchPage .item1 .news-list li:last-child {
          margin-bottom: 0; } }
    @media (min-width: 768px) {
      .searchPage .item1 .news-list li:last-child {
        margin-bottom: 0; }
      .searchPage .item1 .news-list li:nth-last-child(2) {
        margin-bottom: 0; } }
    .searchPage .item1 .news-list li a {
      display: block; }
      @media (min-width: 768px) {
        .searchPage .item1 .news-list li a:hover .ti {
          color: #FCAD28; } }
      .searchPage .item1 .news-list li a .ti {
        font-size: 1.25rem;
        line-height: 1.5;
        letter-spacing: 0.5px;
        font-weight: 400;
        color: #000000;
        transition: 0.4s ease; }
  @media (max-width: 767px) {
    .searchPage .item1 .news-list {
      margin-bottom: 50px; } }

.bgcover {
  width: 100%;
  height: 0;
  background: 50% / cover; }

.mo {
  display: none; }
  @media (max-width: 767px) {
    .mo {
      display: block; } }

.pc {
  display: none; }
  @media (min-width: 768px) {
    .pc {
      display: block; } }

.container {
  padding: 0 16px;
  margin: 0 auto;
  width: 100%;
  z-index: 3;
  position: relative; }
  @media (max-width: 767px) {
    .container {
      padding: 0 25px; } }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1232px; } }

#top-menu-ul {
  position: relative;
  z-index: 1;
  padding: 0 88px; }
  @media (max-width: 767px) {
    #top-menu-ul {
      padding: 0 55px; } }
  #top-menu-ul .item_Menu {
    margin: 0 auto;
    overflow: hidden;
    height: 64px; }
  #top-menu-ul .item_menu_Box {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    position: relative;
    text-align: left; }
    #top-menu-ul .item_menu_Box .item_menu_list {
      white-space: nowrap !important;
      padding-bottom: 0px;
      text-align: center;
      font-size: 0px;
      display: inline-flex; }
    #top-menu-ul .item_menu_Box li {
      height: 64px; }
      #top-menu-ul .item_menu_Box li:first-child a {
        margin-left: 0; }
    #top-menu-ul .item_menu_Box li a {
      position: relative;
      display: block;
      line-height: 2.7;
      letter-spacing: 0.6px;
      font-size: 1.25rem;
      color: #707070; }
      @media (max-width: 767px) {
        #top-menu-ul .item_menu_Box li a {
          padding: 7px 0 3px;
          margin: 0 20px 0 0;
          line-height: 2.5; } }
      @media (min-width: 768px) {
        #top-menu-ul .item_menu_Box li a {
          margin: 0 20px;
          padding: 7px 0; }
          #top-menu-ul .item_menu_Box li a:hover {
            color: #FCAD28; } }
    #top-menu-ul .item_menu_Box li.active a {
      color: #FCAD28; }

.flex-direction-nav {
  display: none; }
  .flex-direction-nav a {
    width: 64px;
    height: 64px;
    background-color: #82D1CB;
    transition: 0.4s ease;
    display: block;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    z-index: 10; }
    .flex-direction-nav a:after {
      position: absolute;
      top: 0; }
    @media (max-width: 767px) {
      .flex-direction-nav a {
        width: 36px;
        height: 36px; } }
  .flex-direction-nav a.flex-next {
    right: 0; }
    .flex-direction-nav a.flex-next::before, .flex-direction-nav a.flex-next::after {
      position: absolute;
      content: "";
      width: 1px;
      height: 20px;
      background-color: #fff;
      left: 50%;
      transition: 0.4s ease; }
      @media (max-width: 767px) {
        .flex-direction-nav a.flex-next::before, .flex-direction-nav a.flex-next::after {
          height: 16px; } }
    .flex-direction-nav a.flex-next::before {
      top: 17px;
      transform: translateX(-50%) rotate(-45deg); }
      @media (max-width: 767px) {
        .flex-direction-nav a.flex-next::before {
          top: 5px; } }
    .flex-direction-nav a.flex-next::after {
      top: 31px;
      transform: translateX(-50%) rotate(45deg); }
      @media (max-width: 767px) {
        .flex-direction-nav a.flex-next::after {
          top: 16px; } }
    @media (min-width: 768px) {
      .flex-direction-nav a.flex-next:hover {
        background-color: #fff; }
        .flex-direction-nav a.flex-next:hover::before, .flex-direction-nav a.flex-next:hover::after {
          background-color: #cccccc; } }
  .flex-direction-nav a.flex-prev {
    left: 0px; }
    .flex-direction-nav a.flex-prev::before, .flex-direction-nav a.flex-prev::after {
      position: absolute;
      content: "";
      width: 1px;
      height: 20px;
      background-color: #fff;
      left: 50%;
      transition: 0.4s ease; }
      @media (max-width: 767px) {
        .flex-direction-nav a.flex-prev::before, .flex-direction-nav a.flex-prev::after {
          height: 16px; } }
    .flex-direction-nav a.flex-prev::before {
      top: 17px;
      transform: translateX(-50%) rotate(45deg); }
      @media (max-width: 767px) {
        .flex-direction-nav a.flex-prev::before {
          top: 5px; } }
    .flex-direction-nav a.flex-prev::after {
      top: 31px;
      transform: translateX(-50%) rotate(-45deg); }
      @media (max-width: 767px) {
        .flex-direction-nav a.flex-prev::after {
          top: 16px; } }
    @media (min-width: 768px) {
      .flex-direction-nav a.flex-prev:hover {
        background-color: #fff; }
        .flex-direction-nav a.flex-prev:hover::before, .flex-direction-nav a.flex-prev:hover::after {
          background-color: #cccccc; } }

.open_flexslider .flex-direction-nav {
  display: block; }

#particles-js {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2; }

.sh-des {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 1px;
  font-weight: 300;
  color: #000000; }
  @media (max-width: 767px) {
    .sh-des {
      font-size: 1.25rem;
      line-height: 1.5;
      letter-spacing: 1px;
      font-weight: 300;
      color: #000000; } }

.sh-dotbtn {
  display: block;
  max-width: 312px;
  width: 100%;
  height: 80px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACJJREFUeNpiYCAWfFko7gDE+3GxmaDqLgBxIQH2MLUOIMAAvHwyXyFSB54AAAAASUVORK5CYII=);
  padding: 8px;
  margin: 0 auto; }
  @media (max-width: 767px) {
    .sh-dotbtn {
      height: 60px;
      padding: 6px; } }
  @media (min-width: 768px) {
    .sh-dotbtn:hover .ins-bx {
      background: #FCAD28; }
    .sh-dotbtn:hover span {
      color: #fff; }
    .sh-dotbtn:hover span:before {
      right: 0;
      width: calc(43.5% - 40px);
      background-color: #fff; }
    .sh-dotbtn:hover span:after {
      right: 0;
      background-color: #fff; } }
  .sh-dotbtn .ins-bx {
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding: 0 30px;
    padding-left: 26px;
    transition: 0.4s ease; }
    @media (max-width: 767px) {
      .sh-dotbtn .ins-bx {
        padding: 0 25px; } }
  .sh-dotbtn span.jp {
    padding-right: 140px; }
  .sh-dotbtn span {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: left;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 2px;
    font-weight: 300;
    color: #FCAD28;
    transition: 0.4s ease; }
    .sh-dotbtn span::before {
      position: absolute;
      content: "";
      width: 43.5%;
      height: 1px;
      top: 50%;
      transform: translateY(-50%);
      right: 40px;
      background-color: #FCAD28;
      transition: 0.4s ease; }
      @media (max-width: 767px) {
        .sh-dotbtn span::before {
          right: 30px;
          width: calc(43.5% + 30px); } }
    .sh-dotbtn span::after {
      position: absolute;
      content: "";
      width: 5px;
      height: 5px;
      top: 50%;
      transform: translateY(-50%);
      right: 40px;
      background-color: #FCAD28;
      border-radius: 50%;
      transition: 0.4s ease; }
      @media (max-width: 767px) {
        .sh-dotbtn span::after {
          right: 30px; } }

.serlist {
  position: relative; }
  .serlist .slick-list {
    padding: 0 7px;
    padding-bottom: 7px; }
    @media (max-width: 767px) {
      .serlist .slick-list {
        padding: 0;
        width: calc(100% + 30px);
        position: relative;
        left: -15px; } }
  .serlist li {
    padding: 0 7px; }
    @media (max-width: 767px) {
      .serlist li {
        padding: 0 15px;
        padding-bottom: 8px; } }
  .serlist a {
    display: block; }
    .serlist a .img-bx img {
      width: 100%; }
    @media (max-width: 767px) {
      .serlist a {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); } }
    @media (min-width: 768px) {
      .serlist a:hover {
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
        .serlist a:hover .sh-dotbtn .ins-bx {
          background: #FCAD28; }
        .serlist a:hover .sh-dotbtn span {
          color: #fff; }
        .serlist a:hover .sh-dotbtn span:before {
          right: 0;
          width: 140px;
          background-color: #fff; }
        .serlist a:hover .sh-dotbtn span:after {
          right: 0;
          background-color: #fff; } }
  .serlist .des-bx {
    padding: 30px 40px 35px 40px; }
    @media (max-width: 767px) {
      .serlist .des-bx {
        padding: 20px 20px 30px 20px; } }
    .serlist .des-bx .t {
      font-size: 1.25rem;
      line-height: 1.5;
      letter-spacing: 1px;
      font-weight: 400;
      color: #000;
      margin-bottom: 13px; }
      @media (max-width: 767px) {
        .serlist .des-bx .t {
          margin-bottom: 8px;
          font-size: 1.625rem;
          line-height: 1.5;
          letter-spacing: 1px;
          font-weight: 400;
          color: #000; } }
    .serlist .des-bx .sh-des {
      margin-bottom: 33px; }
      @media (max-width: 767px) {
        .serlist .des-bx .sh-des {
          margin-bottom: 25px; } }
  .serlist .slick-prev {
    width: 64px;
    height: 64px;
    background-color: #82D1CB;
    transition: 0.4s ease;
    left: -137px; }
    @media (max-width: 1480px) {
      .serlist .slick-prev {
        left: -25px; } }
    @media (max-width: 767px) {
      .serlist .slick-prev {
        width: 40px;
        height: 40px;
        top: calc(50% - 123px);
        left: -10px; } }
    .serlist .slick-prev::before, .serlist .slick-prev::after {
      position: absolute;
      content: "";
      width: 1px;
      height: 20px;
      background-color: #fff;
      left: 50%;
      transition: 0.4s ease; }
      @media (max-width: 767px) {
        .serlist .slick-prev::before, .serlist .slick-prev::after {
          height: 16px; } }
    .serlist .slick-prev::before {
      top: 17px;
      transform: translateX(-50%) rotate(45deg); }
      @media (max-width: 767px) {
        .serlist .slick-prev::before {
          top: 8px; } }
    .serlist .slick-prev::after {
      top: 31px;
      transform: translateX(-50%) rotate(-45deg); }
      @media (max-width: 767px) {
        .serlist .slick-prev::after {
          top: 19px; } }
    @media (min-width: 768px) {
      .serlist .slick-prev:hover {
        background-color: #fff; }
        .serlist .slick-prev:hover::before, .serlist .slick-prev:hover::after {
          background-color: #cccccc; } }
  .serlist .slick-next {
    width: 64px;
    height: 64px;
    background-color: #82D1CB;
    transition: 0.4s ease;
    right: -137px; }
    @media (max-width: 1480px) {
      .serlist .slick-next {
        right: -25px; } }
    @media (max-width: 767px) {
      .serlist .slick-next {
        width: 40px;
        height: 40px;
        top: calc(50% - 123px);
        right: -10px; } }
    .serlist .slick-next::before, .serlist .slick-next::after {
      position: absolute;
      content: "";
      width: 1px;
      height: 20px;
      background-color: #fff;
      left: 50%;
      transition: 0.4s ease; }
      @media (max-width: 767px) {
        .serlist .slick-next::before, .serlist .slick-next::after {
          height: 16px; } }
    .serlist .slick-next::before {
      top: 17px;
      transform: translateX(-50%) rotate(-45deg); }
      @media (max-width: 767px) {
        .serlist .slick-next::before {
          top: 8px; } }
    .serlist .slick-next::after {
      top: 31px;
      transform: translateX(-50%) rotate(45deg); }
      @media (max-width: 767px) {
        .serlist .slick-next::after {
          top: 19px; } }
    @media (min-width: 768px) {
      .serlist .slick-next:hover {
        background-color: #fff; }
        .serlist .slick-next:hover::before, .serlist .slick-next:hover::after {
          background-color: #cccccc; } }

.sh-banner {
  position: relative;
  z-index: 3; }
  .sh-banner .r-bx {
    width: 1368px;
    margin-left: auto; }
    @media (max-width: 1600px) {
      .sh-banner .r-bx {
        width: 71.25vw; } }
    @media (max-width: 767px) {
      .sh-banner .r-bx {
        width: 100%; } }
    .sh-banner .r-bx .bgcover {
      background-size: cover !important;
      padding-bottom: 360px; }
      @media (max-width: 767px) {
        .sh-banner .r-bx .bgcover {
          padding-bottom: 50.1%; } }
  .sh-banner .l-card {
    position: absolute;
    right: 1158px;
    top: 50%;
    transform: translateY(-50%);
    width: 745px;
    height: 240px;
    background-color: #82D1CB;
    padding-left: 345px; }
    @media (max-width: 1600px) {
      .sh-banner .l-card {
        right: auto;
        left: -300px; } }
    @media (max-width: 767px) {
      .sh-banner .l-card {
        left: 0;
        width: 160px;
        height: 102px;
        padding-left: 20px;
        padding-right: 10px;
        bottom: -20px;
        top: auto;
        transform: none; } }
    .sh-banner .l-card .cen-bx {
      position: relative;
      top: 50%;
      transform: translateY(-50%); }
      @media (max-width: 767px) {
        .sh-banner .l-card .cen-bx {
          top: 44%; } }
    .sh-banner .l-card .e-ti {
      font-size: 3rem;
      line-height: 1.2;
      letter-spacing: 0px;
      font-weight: 300;
      color: #fff; }
      @media (max-width: 767px) {
        .sh-banner .l-card .e-ti {
          font-size: 1.875rem;
          font-weight: 700; } }
    .sh-banner .l-card .t {
      font-size: 1.5rem;
      line-height: 1.2;
      letter-spacing: 2px;
      font-weight: 300;
      color: #fff; }
      @media (max-width: 767px) {
        .sh-banner .l-card .t {
          font-size: 1.125rem; } }
      .sh-banner .l-card .t span {
        position: relative;
        padding-top: 16px;
        margin-top: 16px; }
        @media (max-width: 767px) {
          .sh-banner .l-card .t span {
            padding-top: 8px;
            margin-top: 6px; } }
        .sh-banner .l-card .t span::before {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          width: 40px;
          height: 1px;
          background-color: #fff; }

.serlist .tag a,
.key-bx .tag {
  display: inline-block;
  color: #82d1cb; }

@media (max-width: 767px) {
  .serlist .tag a {
    box-shadow: none; } }

.serlist li {
  cursor: pointer; }

.serlist a:hover {
  box-shadow: none; }

.key-bx {
  margin-top: 48px; }

body {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none; }
